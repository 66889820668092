import { StoreEntity } from '@shared/classes/store.collection';
import { VehicleConnectionStatusesEnum } from '../enum/vehicle-connection-statuses.enum';
import { VehicleParkingStatusesEnum } from '../enum/vehicle-parking-statuses.enum';


export class VehicleCoordinates implements StoreEntity {

  constructor(
    public id: string,
    public lastMessageTime: string,
    public parkingStatus: VehicleParkingStatusesEnum,
    public connectionStatus: VehicleConnectionStatusesEnum,
    public speed: number,
    public latitude: number,
    public longitude: number,
    public targetPlatformId: string | null,
  ) {
  }

}
