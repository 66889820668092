import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment-timezone';

export class RsqlFilter {
  private constructor(private filters: { [key: string]: { [operator in RsqlOperator]?: string } } = {}) {
  }

  static get EMPTY() {
    return new RsqlFilter();
  }

  static createByTemplate(value: { [key: string]: any }, filters: RsqlFilter, template: FilterTemplate[]) {
    let result: RsqlFilter = RsqlFilter.EMPTY;

    template.forEach(([resultPropertyName, operator, valuePropertyName]) => {
      const filter = filters.get(resultPropertyName, operator) || value[valuePropertyName];
      result.set(resultPropertyName, operator, filter);
    });

    return result;
  }

  has(key: string, operator?: RsqlOperator) {
    return operator !== undefined ? !!this.filters[key][operator] : !!this.filters[key];
  }

  get(key: string, operator: RsqlOperator): any {
    return this.filters[key] && this.filters[key][operator] || null;
  }

  changeTimezone(from: string, to: string) {
    const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{3})?Z$/;
    this.reduceFilters((key: string, operator: RsqlOperator, value: string) => {
      if (value.match(pattern)) {
        const timeInPrevZone = moment.tz(value, from).format('YYYYMMDDHHmmss');
        const timeInNewZone = moment.tz(timeInPrevZone, 'YYYYMMDDHHmmss', to).toISOString();
        this.filters[key][operator] = timeInNewZone;
      }
    });
  }

  set(key: string, operator: RsqlOperator, value: any) {
    value = this.stringifyValue(value);
    this.filters[key] = this.filters[key] || {};
    this.filters[key][operator] = value;
    return this;
  }

  remove(key: string, operator?: RsqlOperator) {
    if (operator !== undefined) {
      delete this.filters[key][operator];
    } else {
      delete this.filters[key];
    }
    return this;
  }

  clear() {
    this.filters = {};
    return this;
  }

  clone() {
    return new RsqlFilter(cloneDeep(this.filters));
  }

  toString(): string {
    return this.reduceFilters((key, operator, value) => key + operator + value).join(';');
  }

  toUriEncodedString(): string {
    return encodeURIComponent(this.toString());
  }

  // применяет fn к каждому значению в фильтре, возвращает результаты применения в виде массива
  private reduceFilters(fn: (key: string, operator: RsqlOperator, value: string) => any): any[] {
    const result: any[] = [];
    Object.entries(this.filters).forEach(([key, ops]) => {
      Object.entries(ops).forEach(([operator, value]: [RsqlOperator, string]) => {
        result.push(fn(key, operator, value));
      });
    });
    return result;
  }

  private stringifyValue(value: any) {
    if (moment.isMoment(value)) {
      return value.toISOString();
    }

    return String(value);
  }
}

export enum RsqlOperator {
  equals = '==',
  search = '=q=',
  like = '=like',
  in = '=in=',
  notEquals = '!=',
  notLike = '=notlike=',
  fromTo = '=rng=',
  lesser = '=lt=',
  greater = '=gt=',
  lesserOrEqual = '=le=',
  greaterOrEqual = '=ge=',
}

export type FilterTemplate = [string, RsqlOperator, string];
