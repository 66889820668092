import { Injectable } from '@angular/core';
import { FillingPointJson } from '../json/filling-point.json';
import { FillingPoint } from './filling-point';


@Injectable({ providedIn: 'root' })
export class FillingPointsFactory {

  public createFillingPoint(json: FillingPointJson) {
    return new FillingPoint(
      json.id,
      json.code,
      json.name,
      json.airport_iata_code,
      json.is_military,
    );
  }

}
