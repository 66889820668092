import { Injectable, OnDestroy } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkShifts } from './work-shifts';
import { WorkShiftsDatesRange } from './work-shifts-dates-range';


@Injectable({
  providedIn: 'root',
})
export class WorkShiftService implements OnDestroy {

  // todo: вероятно будет зависить от аэропорта
  private static readonly WORK_SHIFT_START_HOURS = 8;

  private static readonly WORK_SHIFT_DURATION = 12;

  private static readonly DEFAULT_WORK_SHIFTS_START = -6;

  private static readonly DEFAULT_WORK_SHIFTS_DURATION = 9;

  private static readonly POOLING_PERIOD = 1000 * 20;

  private activeWorkShiftsSubject: BehaviorSubject<WorkShifts>;

  private destroy = new Subject<void>();

  constructor(
    private currentAirportService: CurrentAirportService,
  ) {
    this.initWorkShifts();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public getActiveWorkShifts() {
    return this.activeWorkShiftsSubject.asObservable();
  }

  public getLastActiveWorkShifts() {
    return this.activeWorkShiftsSubject.getValue();
  }

  public next(shift = 1) {
    const workShifts = this.getLastActiveWorkShifts();
    this.activeWorkShiftsSubject.next(new WorkShifts(workShifts.start + shift, workShifts.duration));
  }

  public prev(shift = 1) {
    const workShifts = this.getLastActiveWorkShifts();
    this.activeWorkShiftsSubject.next(new WorkShifts(workShifts.start - shift, workShifts.duration));
  }

  public getWorkShiftsDates(workShifts: WorkShifts) {
    const baseDate = this.getStartDateOfCurrentWorkShift();

    const result = [];
    for (let i = workShifts.start, l = workShifts.start + workShifts.duration; i <= l; i++) {
      result.push(baseDate.clone().add(WorkShiftService.WORK_SHIFT_DURATION * i, 'hours'));
    }

    return result;
  }

  public getWorkShiftsDatesRange(workShifts: WorkShifts) {
    const baseDate = this.getStartDateOfCurrentWorkShift();

    const start = baseDate.clone().add(WorkShiftService.WORK_SHIFT_DURATION * workShifts.start, 'hours');
    const finish = start.clone().add(WorkShiftService.WORK_SHIFT_DURATION * (workShifts.duration + 1), 'hours');

    return new WorkShiftsDatesRange(start, finish);
  }

  public getStartDateOfCurrentWorkShift() {
    const hours = moment().hours();

    if (hours < WorkShiftService.WORK_SHIFT_START_HOURS) {
      return moment()
        .subtract(1, 'days')
        .startOf('minutes')
        .set('hours', WorkShiftService.WORK_SHIFT_START_HOURS + WorkShiftService.WORK_SHIFT_DURATION)
        .set('minutes', 0);
    } else if (WorkShiftService.WORK_SHIFT_START_HOURS + WorkShiftService.WORK_SHIFT_DURATION <= hours) {
      return moment()
        .startOf('minutes')
        .set('hours', WorkShiftService.WORK_SHIFT_START_HOURS + WorkShiftService.WORK_SHIFT_DURATION)
        .set('minutes', 0);
    }

    return moment()
      .startOf('minutes')
      .set('hours', WorkShiftService.WORK_SHIFT_START_HOURS)
      .set('minutes', 0);
  }

  private getWorkShifts(): WorkShifts {
    // todo: сделать настраиваемым!
    const MURMANSK_IATA = 'MMK';
    const TOLMACHEVO_IATA = 'OVB';
    const GAGARIN_IATA = 'GSV';
    const SVO_IATA = 'SVO';
    const LED_IATA = 'LED';

    const currentIATA = this.currentAirportService.currentIATA;

    if (currentIATA === MURMANSK_IATA || currentIATA === TOLMACHEVO_IATA || currentIATA === GAGARIN_IATA) {
      return new WorkShifts(-4, 9);
    } else if (currentIATA === SVO_IATA) {
      return new WorkShifts(-2, 4);
    } else if (currentIATA === LED_IATA) {
      return new WorkShifts(-3, 7);
    }

    return new WorkShifts(
      WorkShiftService.DEFAULT_WORK_SHIFTS_START,
      WorkShiftService.DEFAULT_WORK_SHIFTS_DURATION,
    );
  }

  private initWorkShifts() {
    this.activeWorkShiftsSubject =  new BehaviorSubject<WorkShifts>(this.getWorkShifts());
    // todo: переписать на пулинг фактических дат, а не номеров рабочих смен

    timer(WorkShiftService.POOLING_PERIOD, WorkShiftService.POOLING_PERIOD)
      .pipe(
        takeUntil(this.destroy),
      )
      .subscribe(() => this.activeWorkShiftsSubject.next(this.getWorkShifts()));
  }

}
