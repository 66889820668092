import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment-timezone";

export function convertNgbDateToDate(value: NgbDate) {
  const m = moment({
    year: value.year,
    month: value.month - 1,
    day: value.day,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return m.toDate();
}
