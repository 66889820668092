import { Pipe, PipeTransform } from "@angular/core";
import { DateTimeFormatter } from "@shared/services/utility/date-time-formatter.service";

@Pipe({
  name: "formatDate",
})
export class FormatDatePipe implements PipeTransform {
  constructor(private formatter: DateTimeFormatter) {
  }

  public transform(value: any, formatString?: string, timezone?: string, emptyLabel = '-'): string {
    return value ? this.formatter.format(value, formatString, timezone) : emptyLabel;
  }
}
