import { Injectable, OnDestroy } from '@angular/core';
import { StoreCollection } from '@shared/classes/store.collection';
import * as moment from 'moment-timezone';
import { Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { WorkShiftService } from '../../../work-shift/work-shift.service';
import { VehicleTaskStatusesEnum } from '../enum/vehicle-task-statuses.enum';
import { VehicleTaskFueling } from '../models/vehicle-task-fueling';
import { VehicleTask } from '../models/vehicle-task.interface';
import { VehiclesTasksService } from '../vehicles-tasks.service';


@Injectable()
export class VehiclesTasksStore implements OnDestroy {

  private static CHANGES_POLLING_INTERVAL = 30000;

  public readonly tasks = new StoreCollection<VehicleTask>();

  private start: moment.Moment;

  private finish: moment.Moment;

  private destroy = new Subject<void>();

  private tickSubject = new Subject<void>();

  constructor(
    private vehiclesTasksService: VehiclesTasksService,
    private workShiftService: WorkShiftService,
  ) {
    this.initWorkShifts();
    this.initCheckChanges();
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public lastValue() {
    return this.tasks.lastValue();
  }

  public reLoadTasks() {
    this
      .vehiclesTasksService
      .loadTasks(this.start, this.finish, [])
      .subscribe(tasks => this.tasks.rewrite(tasks));
  }

  public tick() {
    return this.tickSubject.asObservable();
  }

  public findById(id: string): VehicleTask | null {
    const tasks = this.tasks.lastValue();
    if (!tasks) {
      return null;
    }

    return tasks.get(id) || null;
  }

  public findByVehicleId(vehicleId: string): VehicleTask[] {
    const tasks = this.tasks.lastValue();
    if (!tasks) {
      return [];
    }

    return [...tasks.values()].filter(task => task.vehicleId === vehicleId);
  }

  public addTask(task: VehicleTaskFueling): void {
    this.tasks.set([task]);
  }

  private initCheckChanges() {
    timer(VehiclesTasksStore.CHANGES_POLLING_INTERVAL, VehiclesTasksStore.CHANGES_POLLING_INTERVAL)
      .pipe(
        takeUntil(this.destroy),
        filter(() => !this.tasks.isEmpty()),
        map(() => moment().subtract(VehiclesTasksStore.CHANGES_POLLING_INTERVAL, 'milliseconds')),
        switchMap(() => this.vehiclesTasksService.loadTasks(
          this.start,
          this.finish,
          [],
        )),
      )
      .subscribe(tasks => this.setTasksAndTick(tasks));
  }

  private initWorkShifts() {
    this
      .workShiftService
      .getActiveWorkShifts()
      .pipe(
        takeUntil(this.destroy),
        map(workShifts => this.workShiftService.getWorkShiftsDatesRange(workShifts)),
      )
      .subscribe(range => {
        this.start = range.start;
        this.finish = range.finish;
        this.reLoadTasks();
      });
  }

  private setTasksAndTick(tasks: VehicleTask[]) {
    if (tasks.length > 0 || this.tasks.lastValue().size > 0) {
      this.tasks.rewrite(tasks, false);
    }

    this.tickSubject.next();
  }
}
