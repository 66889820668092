import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.css'],
})
export class StaticTextComponent {

  @Input() text: string;
  @Input() cssClass: string;
  
}
