import { Injectable } from '@angular/core';
import { returnVoid } from '@shared/functions/return-void.function';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AirportsService } from './airports.service';
import { Airport } from './models/airport';


@Injectable({ providedIn: 'root' })
export class AirportsStore {

  private airportsSubject = new BehaviorSubject<Airport[]>([]);

  private indexById = new Map<number, Airport>();

  private indexByName = new Map<string, Airport>();

  private indexByCityName = new Map<string, Airport>();

  constructor(
    private airportsService: AirportsService,
  ) {
  }

  public init(): Observable<void> {
    return this
      .airportsService
      .load()
      .pipe(
        tap(airline => this.set(airline)),
        map(returnVoid),
      );
  }

  public get(): Airport[] {
    return this.airportsSubject.getValue();
  }

  public findById(id: number): Airport | null {
    return this.indexById.get(id) || null;
  }

  public findByName(name: string): Airport | null {
    return this.indexByName.get(name) || null;
  }

  public findByCityName(cityName: string): Airport | null {
    return this.indexByCityName.get(cityName) || null;
  }

  public changes(): Observable<Airport[]> {
    return this.airportsSubject.asObservable();
  }

  public set(airports: Airport[]): void {
    this.indexById = new Map<number, Airport>();
    this.indexByName = new Map<string, Airport>();
    this.indexByCityName = new Map<string, Airport>();

    airports.forEach(airline => {
      this.indexById.set(airline.id, airline);
      this.indexByName.set(airline.name, airline);
      this.indexByCityName.set(airline.cityName, airline);
    });

    this.airportsSubject.next(airports);
  }

}

