export class WorkShifts {
  constructor(
    public start: number,
    public duration: number,
  ) {
  }

  public get finish() {
    return this.start + this.duration;
  }

}
