import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@shared/services/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AircraftJson } from './json/aircraft.json';
import { Aircraft } from './models/aircraft';
import { AircraftsModelsFactory } from './models/aircrafts-models.factory';


@Injectable({ providedIn: 'root' })
export class AircraftsService {

  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private aircraftsModelsFactory: AircraftsModelsFactory,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public load(): Observable<Aircraft[]> {
    return this
      .httpClient
      .get<AircraftJson[]>(`${ this.apiUrl }/v1/aircrafts`)
      .pipe(
        map(jsonItems => jsonItems.map(json => this.aircraftsModelsFactory.createAircraftFromJson(json))),
      );
  }

}
