import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { KeycloakService } from "@shared/services/keycloak.service";
import { fromPromise } from "rxjs/internal-compatibility";

@Injectable({
  providedIn: "root",
})
export class CoreInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ withCredentials: false });

    return fromPromise(this.keycloakService.getToken()).pipe(
            switchMap(token => {
              const headers = request.headers
                    .set("Authorization", "Bearer " + token)
                    .append("Content-Type", "application/json");
              const requestClone = request.clone({
                headers,
              });
              return next.handle(requestClone);
            }),
        );
  }
}
