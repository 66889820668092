import {Injectable} from "@angular/core";

type ToastType = "success" | "info" | "danger";
const startingIndex = 10000;

interface Toast {
  message: string;
  type?: ToastType;
  timeout?: number;
  timer?: number;
  actionText?: string;
  isTimerDisplay?: boolean;
  resolveFunction?: Function; //дефолтная функция которая отрезолвится по окончанию времени
  resolveButtonFunction?: Function; //функция которая активируется по кнопке
  dismissible? : boolean,
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  public map: Map<number, Toast> = new Map<number, Toast>();

  private index: number = startingIndex;

  public close(key: number): void {
    this.delete(key);
  }

  public showMessage(message: any): void {
    this.add({message, type: "success"});
  }

  public showActionMessage(toast: Toast): void {
    this.add(toast);
  }

  public showError(message: string): void {
    this.add({message, type: "danger"});
  }

  public showActionError(toast: Toast): void {
    this.add({...toast, type: "danger"});
  }

  private delete(key: number): void {
    this.map.delete(key);
  }

  private decreaseIndex() {
    this.index--;
    if (this.index == 0) {
      this.index = startingIndex;
    }
  }

  private add(data: Toast): void {
    let {
      message,
      type,
      timeout,
      timer,
      actionText = 'Отменить',
      isTimerDisplay = true,
      resolveFunction,
      resolveButtonFunction,
      dismissible = false,
    } = data;
    timeout = timeout || 10000;
    type = type || "success";

    if (timeout && !timer) {
      this.map.set(this.index, {message, type, timeout, dismissible});
      setTimeout((index: number) => this.delete(index), timeout, this.index);
      this.decreaseIndex();
    } else {
            //set view for first display
      this.map.set(this.index, {message, type, timeout, resolveFunction, timer, dismissible});
      let currentIndex = this.index;
            //get handlers
      let timerHandler = setInterval((index: number) => {
        let toast = this.map.get(index);
        if (toast) {
          toast.timer = toast.timer > 0 ? toast.timer - 1 : 0;
          this.map.set(index, toast);
        } else {
          resolve(index);
        }
      }, 1000, currentIndex);

      let timeoutHandler = setTimeout((index: number) => {
        resolve(index);
      }, timeout, currentIndex);

      const resolve = (index: number) => {
        resolveFunction();
        clearInterval(timerHandler);
        this.delete(index)
      };
            //set resolve function
      let newResolveButtonFunction = () => {
        resolveButtonFunction();
        clearTimeout(timeoutHandler);
        clearInterval(timerHandler);
        this.delete(currentIndex);
      };
            //update map
      this.map.set(currentIndex, {
        message,
        type,
        timeout,
        actionText,
        isTimerDisplay,
        resolveFunction: resolveFunction,
        resolveButtonFunction: newResolveButtonFunction,
        timer,
        dismissible,
      });
            //update index counter
      this.decreaseIndex();
    }
  }


}
