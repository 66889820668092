import { Injectable, OnDestroy } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Airport } from '@shared/models/airport.model';
import { AvailableAirportsService } from '@shared/services/business/available-airports.service';
import { merge, Observable, of, partition, Subject } from 'rxjs';
import { catchError, map, mergeMap, share, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentAirportStorageService implements OnDestroy {
  public static KEY = 'currentAirport';
  private destroy$ = new Subject();

  constructor(
    private availableAirportsService: AvailableAirportsService,
    private storage: StorageMap,
  ) {
  }

  public save(airport: Airport): Observable<undefined> {
    return this.storage.set(CurrentAirportStorageService.KEY, airport.iata_code);
  }

  public load(): Observable<Airport> {
    const available$ = this.availableAirportsService.availableAirports$.pipe(take(1));
    const [nonEmpty$, empty$] =
      partition(
        this.storage.get<string>(CurrentAirportStorageService.KEY)
          .pipe(
            catchError(() => of(null)),
            take(1),
            share(),
          ),
        code => !!code,
      );

    // если ничего не было сохранено, используем фолбек
    const nonLoaded$ = empty$.pipe(
      mergeMap(() => available$),
      map(available => this.getFallbackAirport(available)),
    );

    // если аэропорт был сохранен, проверяем права доступа и отдаём сохранённый
    const loaded$ = nonEmpty$.pipe(
      mergeMap((code: string) => {
          return available$.pipe(map(availableAirports => {
            return this.isAvailable(availableAirports, code)
              ? this.findAvailableAirportByCode(availableAirports, code)
              : this.getFallbackAirport(availableAirports);
          }));
        },
      ),
    );

    return merge(loaded$, nonLoaded$);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private isAvailable(availableAirports: Airport[], code: string): boolean {
    return availableAirports.some(available => available.iata_code === code);
  }

  private getFallbackAirport(availableAirports: Airport[]): Airport {
    const svoAirport = availableAirports.find(airport => airport.iata_code === 'SVO');

    return svoAirport || availableAirports[0] || null;
  }

  private findAvailableAirportByCode(availableAirports: Airport[], code: string): Airport {
    return availableAirports.find(available => available.iata_code === code);
  }
}
