import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appHrefToRouterLink]',
})
export class HrefToRouterLinkDirective implements AfterViewInit, OnDestroy {
  private unsubscribe: Function[] = [];

  constructor(
    private element: ElementRef,
    private router: Router,
    private renderer: Renderer2,
  ) {
  }

  ngAfterViewInit() {
    const navigationElements = [...this.element.nativeElement.querySelectorAll('a[href]')];

    navigationElements.forEach((elem: HTMLAnchorElement) => {
      const mute = this.renderer.listen(elem, 'click', (event) => {
        event.preventDefault();

        const href = elem.getAttribute('href');
        const queryParams = elem.dataset.query && JSON.parse(elem.dataset.query);
        this.router.navigateByUrl(href, {queryParams});
      });

      this.unsubscribe.push(mute);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(fn => fn());
  }
}
