import { Injectable } from '@angular/core';
import { PlatformJson } from '../json/platform.json';
import { Platform } from './platform';


@Injectable({ providedIn: 'root' })
export class PlatformsModelsFactory {

  public createPlatform(json: PlatformJson): Platform {
    return new Platform(
      json.id,
      json.name,
      json.airport_iata_code,
      json.has_dispenser,
      json.latitude,
      json.longitude,
    );
  }

}
