import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Airport } from '@shared/models/airport.model';
import { AvailableAirportsService } from '@shared/services/business/available-airports.service';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { Observable, Subject } from 'rxjs';
import { NavBarDateTimeComponent } from '../nav-bar-date-time/nav-bar-date-time.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent implements OnInit, OnDestroy {
  public activeUrl: string;
  public availableAirports$: Observable<Airport[]>;
  public selectedAirport: Airport;

  @ViewChild(NavBarDateTimeComponent, { static: true })
  public dateTimeComponent: NavBarDateTimeComponent;

  private onDestroy: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private currentAirportService: CurrentAirportService,
    private availableAirportsService: AvailableAirportsService,
  ) {
    this.availableAirports$ = availableAirportsService.availableAirports$;
  }

  public ngOnInit(): void {
    this.activeUrl = this.router.routerState.snapshot.url;
    this.selectedAirport = this.currentAirportService.currentAirport;
  }

  public onPageChange(route: string): void {
    this.router.navigateByUrl(route);
  }

  public changeAirport(airport: Airport) {
    this.currentAirportService.setCurrentAirport(airport);
    this.selectedAirport = airport;
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
