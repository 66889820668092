import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '../models/vehicle';


@Pipe({
  name: 'appVehicleName',
})
export class VehicleNamePipe implements PipeTransform {

  public transform(vehicle: Vehicle | null): string {
    return vehicle ? `№${vehicle.garageNumber}` : 'n/a';
  }

}
