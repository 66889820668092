// todo: вынести в модуль tasks, на этапе схемы

export enum VehicleTaskStatesEnum {
  START = 'START',
  ARRIVED_AT_THE_PARKING_LOT = 'ARRIVED_AT_THE_PARKING_LOT',
  TOOK_REFUELING_POSITION = 'TOOK_REFUELING_POSITION',
  READY_TO_FUELING = 'READY_TO_FUELING',
  FUEL_QUANTITY_REQUEST = 'FUEL_QUANTITY_REQUEST',
  START_FUELING = 'START_FUELING',
  PAUSE_FUELING = 'PAUSE_FUELING',
  RESUME_FUELING = 'RESUME_FUELING',
  STOP_FUELING = 'STOP_FUELING',
  CREATED_SETTLEMENT_ORDER = 'CREATED_SETTLEMENT_ORDER',
  REQUEST_FOR_APPROVAL_OF_SETTLEMENT_ORDER = 'REQUEST_FOR_APPROVAL_OF_SETTLEMENT_ORDER',
  CONFIRMED_SETTLEMENT_ORDER = 'CONFIRMED_SETTLEMENT_ORDER',
  REQUIREMENT_REFUELING = 'REQUIREMENT_REFUELING',
  SAVED_SETTLEMENT_ORDER = 'SAVED_SETTLEMENT_ORDER',
  DONE_FUELING = 'DONE_FUELING',
  LEFT_SERVICE_AREA = 'LEFT_SERVICE_AREA',
  ARRIVED_TO_FILLING_AREA = 'ARRIVED_TO_FILLING_AREA',
  SMALL_FORM_MAINTENANCE = 'SMALL_FORM_MAINTENANCE',
  READY_TO_FILLING = 'READY_TO_FILLING',
  DONE_FILLING = 'DONE_FILLING',
  FILLING_INFO_INPUT = 'FILLING_INFO_INPUT',
  DONE_SETTLING = 'DONE_SETTLING',
  DONE_AIRFIELD_CONTROL = 'DONE_AIRFIELD_CONTROL',
}

export const VEHICLE_TASK_STATES_LABELS = {
  [VehicleTaskStatesEnum.START]: "Начал движение для выполнения задания",
  [VehicleTaskStatesEnum.ARRIVED_AT_THE_PARKING_LOT]: "Прибыл к месту стоянки ВС",
  [VehicleTaskStatesEnum.TOOK_REFUELING_POSITION]: "Занял позицию заправки ВС",
  [VehicleTaskStatesEnum.READY_TO_FUELING]: "Готов к заправке ВС",
  [VehicleTaskStatesEnum.FUEL_QUANTITY_REQUEST]: "Отправлен запрос КВС на согласование количества топлива",
  [VehicleTaskStatesEnum.START_FUELING]: "Начал заправку ВС",
  [VehicleTaskStatesEnum.PAUSE_FUELING]: "Остановил заправку",
  [VehicleTaskStatesEnum.RESUME_FUELING]: "Продолжил заправку",
  [VehicleTaskStatesEnum.STOP_FUELING]: "Закончил заправку ВС",
  [VehicleTaskStatesEnum.CREATED_SETTLEMENT_ORDER]: "Сформирован РО",
  [VehicleTaskStatesEnum.REQUEST_FOR_APPROVAL_OF_SETTLEMENT_ORDER]: "Отправлен запрос КВС на согласование РО",
  [VehicleTaskStatesEnum.CONFIRMED_SETTLEMENT_ORDER]: "Получено подтверждение РО от КВС",
  [VehicleTaskStatesEnum.REQUIREMENT_REFUELING]: "Получено требование о дозаправке от КВС",
  [VehicleTaskStatesEnum.SAVED_SETTLEMENT_ORDER]: "Сохранен РО",
  [VehicleTaskStatesEnum.DONE_FUELING]: "Задание выполнил",
  [VehicleTaskStatesEnum.LEFT_SERVICE_AREA]: "Выехал из зоны обслуживания",
  [VehicleTaskStatesEnum.ARRIVED_TO_FILLING_AREA]: "Прибыл на пункт налива ТЗА",
  [VehicleTaskStatesEnum.SMALL_FORM_MAINTENANCE]: "Малые формы ТО",
  [VehicleTaskStatesEnum.READY_TO_FILLING]: "К наливу готов",
  [VehicleTaskStatesEnum.DONE_FILLING]: "Выполнен налив ТЗА",
  [VehicleTaskStatesEnum.FILLING_INFO_INPUT]: "Регистрация данных о наливе",
  [VehicleTaskStatesEnum.DONE_SETTLING]: "Проведено отстаивание",
  [VehicleTaskStatesEnum.DONE_AIRFIELD_CONTROL]: "Проведена процедура аэродромного контроля",
};

// todo: возможно лучше сделать сервисом?
export class VehicleTaskStates {

  private static readonly order: VehicleTaskStatesEnum[] = [
    VehicleTaskStatesEnum.START,
    VehicleTaskStatesEnum.ARRIVED_AT_THE_PARKING_LOT,
    VehicleTaskStatesEnum.TOOK_REFUELING_POSITION,
    VehicleTaskStatesEnum.READY_TO_FUELING,
    VehicleTaskStatesEnum.FUEL_QUANTITY_REQUEST,
    VehicleTaskStatesEnum.START_FUELING,
    VehicleTaskStatesEnum.PAUSE_FUELING,
    VehicleTaskStatesEnum.RESUME_FUELING,
    VehicleTaskStatesEnum.STOP_FUELING,
    VehicleTaskStatesEnum.CREATED_SETTLEMENT_ORDER,
    VehicleTaskStatesEnum.REQUEST_FOR_APPROVAL_OF_SETTLEMENT_ORDER,
    VehicleTaskStatesEnum.CONFIRMED_SETTLEMENT_ORDER,
    VehicleTaskStatesEnum.REQUIREMENT_REFUELING,
    VehicleTaskStatesEnum.SAVED_SETTLEMENT_ORDER,
    VehicleTaskStatesEnum.DONE_FUELING,
    VehicleTaskStatesEnum.LEFT_SERVICE_AREA,
    VehicleTaskStatesEnum.ARRIVED_TO_FILLING_AREA,
    VehicleTaskStatesEnum.READY_TO_FILLING,
    VehicleTaskStatesEnum.DONE_FILLING,
    VehicleTaskStatesEnum.FILLING_INFO_INPUT,
    VehicleTaskStatesEnum.DONE_SETTLING,
    VehicleTaskStatesEnum.DONE_AIRFIELD_CONTROL,
  ];

  public static less(first: VehicleTaskStatesEnum, second: VehicleTaskStatesEnum) {
    return VehicleTaskStates.diff(first, second) < 0;
  }

  public static greater(first: VehicleTaskStatesEnum, second: VehicleTaskStatesEnum) {
    return VehicleTaskStates.diff(first, second) > 0;
  }

  public static visibleFillingStates(isSuzvs: boolean) {
    if (isSuzvs) {
      return [
        VehicleTaskStatesEnum.START,
        VehicleTaskStatesEnum.ARRIVED_TO_FILLING_AREA,
        VehicleTaskStatesEnum.READY_TO_FILLING,
        VehicleTaskStatesEnum.SMALL_FORM_MAINTENANCE,
        VehicleTaskStatesEnum.DONE_FILLING,
        VehicleTaskStatesEnum.DONE_SETTLING,
        VehicleTaskStatesEnum.DONE_AIRFIELD_CONTROL,
      ];
    } else {
      return [
        VehicleTaskStatesEnum.START,
        VehicleTaskStatesEnum.ARRIVED_TO_FILLING_AREA,
        VehicleTaskStatesEnum.READY_TO_FILLING,
        VehicleTaskStatesEnum.SMALL_FORM_MAINTENANCE,
        VehicleTaskStatesEnum.DONE_FILLING,
        VehicleTaskStatesEnum.FILLING_INFO_INPUT,
        VehicleTaskStatesEnum.DONE_SETTLING,
        VehicleTaskStatesEnum.DONE_AIRFIELD_CONTROL,
      ];
    }
  }

  public static visibleFuelingStates() {
    return [
      VehicleTaskStatesEnum.START,
      VehicleTaskStatesEnum.ARRIVED_AT_THE_PARKING_LOT,
      VehicleTaskStatesEnum.TOOK_REFUELING_POSITION,
      VehicleTaskStatesEnum.READY_TO_FUELING,
      VehicleTaskStatesEnum.START_FUELING,
      VehicleTaskStatesEnum.STOP_FUELING,
      VehicleTaskStatesEnum.DONE_FUELING,
      VehicleTaskStatesEnum.LEFT_SERVICE_AREA,
    ];
  }

  private static diff(first: VehicleTaskStatesEnum, second: VehicleTaskStatesEnum) {
    const firstIndex = VehicleTaskStates.order.findIndex(status => status === first);
    const secondIndex = VehicleTaskStates.order.findIndex(status => status === second);

    return firstIndex - secondIndex;
  }
}
