export interface KeycloakConfig {
  url: string;
  realm: string;
  clientId: string;
  credentials?: Credentials;
}

export enum KeycloakEventType {
    OnAuthError,
    OnAuthLogout,
    OnAuthRefreshError,
    OnAuthRefreshSuccess,
    OnAuthSuccess,
    OnReady,
    OnTokenExpired,
}

export interface KeycloakEvent {
  type: KeycloakEventType;
  args?: any;
}

export interface KeycloakInitOptions {
  onLoad?: KeycloakOnLoad;
  token?: string;
  refreshToken?: string;
  idToken?: string;
  timeSkew?: number;
  checkLoginIframe?: boolean;
  checkLoginIframeInterval?: number | any;
  responseMode?: KeycloakResponseMode;
  flow?: KeycloakFlow;
}

export interface ExcludedUrl {
  url: string;
  httpMethods?: HttpMethods[];
}

export interface ExcludedUrlRegex {
  urlPattern: RegExp;
  httpMethods?: HttpMethods[];
}

export interface KeycloakOptions {
  config?: string | KeycloakConfig;
  initOptions?: KeycloakInitOptions;
  enableBearerInterceptor?: boolean;
  loadUserProfileAtStartUp?: boolean;
  bearerExcludedUrls?: (string | ExcludedUrl)[];
  authorizationHeaderName?: string;
  bearerPrefix?: string;
}

export type KeycloakOnLoad = "login-required" | "check-sso";
export type KeycloakResponseMode = "query" | "fragment";
export type KeycloakFlow = "standard" | "implicit" | "hybrid";
export type Credentials = {
  secret: string;
};
export type HttpMethods =
    | 'GET'
    | 'POST'
    | 'PUT'
    | 'DELETE'
    | 'OPTIONS'
    | 'HEAD'
    | 'PATCH';
