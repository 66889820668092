import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import { map } from 'rxjs/operators';
import { FillingPointJson } from './json/filling-point.json';
import { FillingPointsFactory } from './models/filling-points.factory';


@Injectable({ providedIn: 'root' })
export class FillingPointsService {

  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private fillingPointsFactory: FillingPointsFactory,
    private currentAirportService: CurrentAirportService,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public loadFillingPoints() {
    const query = `airportIataCode==${this.currentAirportService.currentIATA}`;

    return this
      .httpClient
      .get<FillingPointJson[]>(`${this.apiUrl}/v1/filling-points/${query}`)
      .pipe(
        map(fillingPoints => fillingPoints.map(fillingPoint => this.fillingPointsFactory.createFillingPoint(fillingPoint))),
      );
  }

}
