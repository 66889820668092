import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";

import { Moment } from "moment-timezone";
import { getNoun } from "../../../utils/get-noun";

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

@Pipe({
  name: "since",
})
export class SincePipe implements PipeTransform {

  transform(value: Moment): any {
    let passed = moment().diff(value);
    if (passed < second) {
      return "только что";

    } else if (passed < minute) {
      const seconds = Math.trunc(passed / second);
      return `${seconds} ${getNoun(seconds, 'секунду', 'секунды', 'секунд')} назад`;

    } else if (passed < hour) {
      const minutes = Math.trunc(passed / minute);
      return `${minutes} ${getNoun(minutes, 'минуту', 'минуты', 'минут')} назад`;

    } else if (passed < day) {
      const hours = Math.trunc(passed / hour);
      return `${hours} ${getNoun(hours, 'час', 'часа', 'часов')} назад`;

    } else {
      const days = Math.trunc(passed / day);
      return `${days} ${getNoun(days, 'день', 'дня', 'дней')} назад`;

    }
  }
}
