import { Flight } from '../../../flights/common/models/flight';
import { VehicleTaskStatesEnum } from '../enum/vehicle-task-states.enum';
import { VehicleTaskStatusesEnum } from '../enum/vehicle-task-statuses.enum';
import { VehicleTasksTypesEnum } from '../enum/vehicle-tasks-types.enum';
import { VehicleTask } from './vehicle-task.interface';


export class VehicleTaskFueling implements VehicleTask {

  public readonly type = VehicleTasksTypesEnum.FUELING;

  constructor(
    public id: string,
    public blockchainTaskid: string | null,
    public airportId: number | null,
    public vehicleId: string,
    public platformId: number | null,
    public productId: number,
    public paymentTypeId: number,
    public airportIataCode: string,
    public flight: Flight,
    public number: string,
    public localCreateDate: string,
    public driver: string | null,
    public isBlockchain: boolean,
    public status: VehicleTaskStatusesEnum,
    public state: VehicleTaskStatesEnum | null,
    public createdBy: string,
    public approvedByDriver: boolean,
    public approvedByCaptain: boolean,
    public planStartDate: string,
    public planFinishDate: string,
    public factStartDate: string | null,
    public factFinishDate: string | null,
    public fuelingPlanStartDate: string,
    public fuelingPlanFinishDate: string,
    public fuelingFactStartDate: string | null,
    public fuelingFactFinishDate: string | null,
    public planMass: number | null,
    public factMass: number | null,
    public planVolume: number | null,
    public factVolume: number | null,
  ) {
  }
}
