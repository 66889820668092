import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AircraftNumber } from '@shared/models/aircraft-number';
import { AircraftType } from '@shared/models/aircraft-type';
import { Airport } from '@shared/models/airport.model';
import { BasicCatalog } from '@shared/models/basic-catalog';
import { ControlTicket } from '@shared/models/control-ticket';
import { Aircraft } from '@shared/models/flight.model';
import { Platform } from '@shared/models/platform';
import { RemainingFuel, Vehicle } from '@shared/models/vehicles.model';
import { VehicleStates } from '@shared/models/vehicleStates.model';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { AirportsStore } from '../../../widgets/airports/common/airports.store';
import { ConfigService } from '../config.service';
import { Airline } from '@shared/models/airline.model';

// todo: выпилить
@Injectable({
  providedIn: "root",
})
export class AirportInfoService {

  private apiUrl: any;

  protected dataVehicles: BehaviorSubject<Vehicle[]> = new BehaviorSubject([]);
  public dataVehicles$: Observable<Vehicle[]> = this.dataVehicles.asObservable();

  protected _aircraftTypesDictionary$: BehaviorSubject<AircraftType[]> = new BehaviorSubject([]);
  public aircraftTypesDictionary$: Observable<AircraftType[]> = this._aircraftTypesDictionary$.asObservable();
  protected _aircraftsDictionary$: BehaviorSubject<Aircraft[]> = new BehaviorSubject([]);
  public aircraftsDictionary$: Observable<Aircraft[]> = this._aircraftsDictionary$.asObservable();
  protected _aircraftNumbersDictionary$: BehaviorSubject<AircraftNumber[]> = new BehaviorSubject([]);
  public aircraftNumbersDictionary$: Observable<AircraftNumber[]> = this._aircraftNumbersDictionary$.asObservable();
  protected _aircraftParkingDictionary$: BehaviorSubject<Platform[]> = new BehaviorSubject([]);
  public aircraftParkingDictionary$: Observable<Platform[]> = this._aircraftParkingDictionary$.asObservable();
  protected _antiIcingFluidDictionary$: BehaviorSubject<Platform[]> = new BehaviorSubject([]);
  public antiIcingFluidDictionary$: Observable<Platform[]> = this._antiIcingFluidDictionary$.asObservable();

  protected _airlinesDictionary$: BehaviorSubject<Airline[]> = new BehaviorSubject([]);
  public airlinesDictionary$: Observable<Platform[]> = this._airlinesDictionary$.asObservable();

  public stateDictionary = {};
  public statusesDictionary = {};
  public stateVehicleDictionary = {};
  public productsDictionary = {};
  public rejectionReasonsDictionary = {};
  public pvkzDictionary = {};

  constructor(
    private http: HttpClient,
    private config$: ConfigService,
    private currentAirportService: CurrentAirportService,
    private airportsStore: AirportsStore,
  ) {
    this.apiUrl = config$.apiUrl;
  }

    //getCityDictionary(): Observable<City[]> {
    //    return this.http.get(`${this.apiUrl}/city`).pipe(
    //        map(result => {
    //            return result as City[];
    //        }),
    //        catchError((error) => this.authService.errorCheck(error))
    //    );
    //}


  loadAircraftsHandbooks() {
    return combineLatest([
      this.getAircraftTypeDictionary(),
      this.getAircraftNumbersDictionary(),
      this.getPlatformsDictionary(),
      this.getAircraftsDictionary(),
      this.getAirlinesDictionary(),
    ])
  }

  getDictionariesForCreate() {
    return {
      aircraftTypes: this._aircraftTypesDictionary$.getValue(),
      aircraftNumbers: this._aircraftNumbersDictionary$.getValue(),
      aircrafts: this._aircraftsDictionary$.getValue(),
      platforms: this._aircraftParkingDictionary$.getValue(),
      airlines: this._airlinesDictionary$.getValue(),
      airports: this.airportsStore.get(),
    }
  }

  getAirlinesDictionary() {
    return this.http.get(`${this.apiUrl}/v1/catalog/contractors`).pipe(
      map((result: Airline[]) => (
        this._airlinesDictionary$.next(result)
      )),
    );
  }

  getVehiclesDictionary(flightId: string): Observable<Vehicle[]> {
    return this.http.get(`${this.apiUrl}/v1/vehicles/flight/${flightId}/vehicle-flight-states`).pipe(
            map((result: any[]) => (
               result.map(item => (
                 {...item.vehicle, status: item.status} as Vehicle
               ))
            )),
        );
  }

  getVehiclesRemainingFuel(): Observable<RemainingFuel[]> {
    return this.http.get(`${this.apiUrl}/v1/vehicles/airport/${this.currentAirportService.currentIATA}/remain-fuel-volumes`).pipe(
            map(result => {
              return result as RemainingFuel[];
            }),
        );
  }

  loadVehicles(flightId: string) {
    const combine$ = combineLatest([this.getVehiclesDictionary(flightId), this.getVehiclesRemainingFuel(), this.getVehiclesPvkzIds()]);
    return combine$.pipe(
      first(),
      map(([vehicles, fuel, pvkz]) => {
        let arr = vehicles.map((data: Vehicle) => {
          const remainingFuel = fuel.find(fuelItem => fuelItem.vehicle_id === data.id);
          data.current_fuel_volume = remainingFuel && remainingFuel.current_fuel_volume ? remainingFuel.current_fuel_volume : 0;
          data.pvkz = pvkz[data.id] ? pvkz[data.id] : {};
          return {
            ...data,
          };
        });
        this.dataVehicles.next(arr);
          // todo: отрефакторить справочники
        return arr;
      }),
    );
  }

  getVehiclesPvkzIds() {
    return this.http.get(`${this.apiUrl}/v1/control-tickets/recent-control-tickets`).pipe(
            map((data: Array<ControlTicket>) => {
              let result = data.reduce((startingArray, element) => {
                if (!startingArray[element.vehicle_id]) {
                  startingArray[element.vehicle_id] = element;
                } else {
                  let date1 = new Date(startingArray[element.vehicle_id].created_date_time).getTime()
                  let date2 = new Date(element.created_date_time).getTime()
                  if (date2 > date1) {
                    startingArray[element.vehicle_id] = element;
                  }
                }
                return startingArray;
              }, {});
              return result;
            }),
        );
  }

  loadPvkzDictionary(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/catalog/anti-icing-fluid-brands`).pipe(
            map((result: Array<BasicCatalog>) => {
              let newStates = {};
              result.forEach(data => {
                newStates[data.id] = data.name;
              })
              this.pvkzDictionary = newStates;
              this._antiIcingFluidDictionary$.next(result);
              return result;
            }),
        );
  }

  getVehicleStatesDictionary(): Observable<VehicleStates[]> {
    return this.http.get(`${this.apiUrl}/v1/catalog/vehicle-states`).pipe(
            map(result => {
              console.info('vehicle-states: ' + (result as VehicleStates[]));
              return result as VehicleStates[];
            }),
        );
  }

  getAircraftTypeDictionary(): Observable<AircraftType[]> {
    return this.http.get(`${this.apiUrl}/v1/aircrafts/types`).pipe(
      map((result: AircraftType[]) => this.filterEmptyItems(result)),
      tap((result: AircraftType[]) => {
        this._aircraftTypesDictionary$.next(result);
        return result;
      }),
    );
  }

  getAircraftNumbersDictionary(): Observable<AircraftNumber[]> {
    return this.http.get(`${this.apiUrl}/v1/aircrafts/numbers`).pipe(
      map((result: AircraftNumber[]) => this.filterEmptyItems(result)),
      tap((result: AircraftNumber[]) => {
        this._aircraftNumbersDictionary$.next(result);
        return result;
      }),
    );
  }

  getAircraftsDictionary(): Observable<Aircraft[]> {
    return this.http.get(`${this.apiUrl}/v1/aircrafts`).pipe(
      map((result: Aircraft[]) => {
        this._aircraftsDictionary$.next(result);
        return result;
      }),
    );
  }

  getPlatformsDictionary(): Observable<Platform[]> {
    return this.http.get(`${this.apiUrl}/v1/catalog/platforms/by-airport/${this.currentAirportService.currentIATA}`)
      .pipe(
        map((result: Platform[]) => this.filterEmptyItems(result)),
        map((result: Platform[]) => {
          this._aircraftParkingDictionary$.next(result);
          return result;
        }),
      );
  }

  private loadDefaultDictionary(url: string, variable: string): Observable<BasicCatalog[]> {
    return this.http.get(`${this.apiUrl}${url}`).pipe(
            map((result: Array<BasicCatalog>) => {
              let newStates = {};
              result.forEach(data => {
                newStates[data.id] = data.name;
              })
              this[variable] = newStates;
              return result;
            }),
        );
  }

  loadStateDictionary(): Observable<BasicCatalog[]> {
    return this.loadDefaultDictionary('/v1/catalog/task-states', 'stateDictionary')
  }

  loadStatusesDictionary(): Observable<BasicCatalog[]> {
    return this.loadDefaultDictionary('/v1/catalog/task-statuses', 'statusesDictionary')
  }

  loadVehicleStateDictionary(): Observable<BasicCatalog[]> {
    return this.loadDefaultDictionary('/v1/catalog/vehicle-states', 'stateVehicleDictionary')
  }

  loadProductsDictionary(): Observable<BasicCatalog[]> {
    return this.loadDefaultDictionary('/v1/catalog/products', 'productsDictionary')
  }

  loadRejectionReasonsDictionary(): Observable<BasicCatalog[]> {
    return this.loadDefaultDictionary('/v1/catalog/rejection-suspense-reasons', 'rejectionReasonsDictionary')
  }

  private filterEmptyItems(items: {id: number, name: string}[]) {
    return items.filter(item => item.name && item.name.trim().length > 0);
  }

}
