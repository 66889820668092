import { Injectable } from '@angular/core';
import { Airport } from '@shared/models/airport.model';
import { AirportsService } from '@shared/services/dictionary/airports.service';
import { UserContextService } from '@shared/services/system/user-context.service';
import { Observable } from 'rxjs';
import { map, pluck, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AvailableAirportsService {
  public availableAirports$: Observable<Airport[]>;

  constructor(
    private userContextService: UserContextService,
    private airportsService: AirportsService,
  ) {
    this.init();
  }

  private init() {
    this.availableAirports$ = this.userContextService.context$.pipe(
      pluck('roles'),
      map(getIATAsFromRoles),
      switchMap(codes => {
        return this.airportsService.getAirportsByIATACodes(codes)
          .pipe(
            map(airports => airports.filter(airport => codes.includes(airport.iata_code))),
          );
      }),
      shareReplay(1),
    );
  }
}

function getIATAsFromRoles(roles: string[]) {
  // todo: путь пока будет
  console.log('roles', roles);
  return roles.filter(role => role.startsWith('Airport-'))
    .map(role => role.substr(8));
}
