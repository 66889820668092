import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { KeycloakService } from "./keycloak.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export abstract class KeycloakGuard implements CanActivate {
  protected authenticated: boolean;
  protected roles: string[];

  protected constructor(protected router: Router, protected keycloakAngular: KeycloakService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let result = false;
        this.authenticated = await this.keycloakAngular.isLoggedIn();
        if (!this.authenticated) {
          await this.keycloakAngular.login();
          return;
        }
        if (route.data.roles) {
          for (let role of route.data.roles) {
            if (await this.keycloakAngular.isUserInRole(role)) {
              // console.log("Access for " + role + " is allowed");
              result = true;
            }
          }
        } else {
          // console.log("Access for all the roles is allowed");
          result = true;
        }
        if (!result) await this.router.navigate(["not-found"], {});
        resolve(result);
      } catch (error) {
        reject("An error happened during access validation: " + error);
      }
    });
  }
}
