import { Injectable } from '@angular/core';
import { FlightsFactory } from '../../../flights/common/models/flights.factory';
import { TaskFillingHistoryItemJson } from '../json/task-filling-history-item.json-interface';
import { TaskFuelingHistoryItemJson } from '../json/task-fueling-history-item.json-interface';
import { VehicleTaskFillingJson } from '../json/vehicle-task-filling.json-interface';
import { VehicleTaskFuelingJson } from '../json/vehicle-task-fueling.json-interface';
import { TaskFillingHistoryItem } from './task-filling-history-item';
import { TaskFuelingHistoryItem } from './task-fueling-history-item';
import { VehicleTaskFilling } from './vehicle-task-filling';
import { VehicleTaskFueling } from './vehicle-task-fueling';


@Injectable()
export class VehiclesTasksModelsFactory {

  constructor(private flightsModelsFactory: FlightsFactory) {
  }

  public createTaskFilling(json: VehicleTaskFillingJson) {
    return new VehicleTaskFilling(
      json.id,
      json.airport_id,
      json.filling_plan_point_id,
      json.filling_fact_point_id,
      json.vehicle_id,
      json.airport_iata_code,
      json.number,
      json.local_create_date_time,
      json.driver_login || null,
      json.status,
      json.state,
      json.created_by,
      json.approved_by_driver,
      json.plan_start_date_time,
      json.plan_finish_date_time,
      json.fact_start_date_time,
      json.fact_finish_date_time,
      json.filling_plan_start_date_time,
      json.filling_plan_finish_date_time,
      json.filling_fact_start_date_time,
      json.filling_fact_finish_date_time,
      json.plan_mass,
      json.fact_mass,
      json.plan_volume,
      json.fact_volume,
    );
  }

  public createTaskFueling(json: VehicleTaskFuelingJson) {
    return new VehicleTaskFueling(
      json.id,
      json.bch_task_id,
      json.airport_id,
      json.vehicle_id,
      json.platform_id,
      json.product_id,
      json.payment_type_id,
      json.airport_iata_code,
      this.flightsModelsFactory.createFlight(json.flight),
      json.number,
      json.local_create_date_time,
      json.driver_login || null,
      json.is_blockchain,
      json.status,
      json.state,
      json.created_by,
      json.approved_by_driver,
      json.approved_by_captain,
      json.plan_start_date_time,
      json.plan_finish_date_time,
      json.fact_start_date_time,
      json.fact_finish_date_time,
      json.refueling_plan_start_date_time,
      json.refueling_plan_finish_date_time,
      json.refueling_fact_start_date_time,
      json.refueling_fact_finish_date_time,
      json.plan_mass,
      json.fact_mass,
      json.plan_volume,
      json.fact_volume,
    );
  }

  public createTaskFillingHistoryItem(json: TaskFillingHistoryItemJson) {
    return new TaskFillingHistoryItem(
      json.id,
      json.filling_id,
      json.filling_point_id,
      json.platform_id,
      json.reason_id,
      json.date_time,
      json.task_number,
      json.task_status,
      json.task_state,
      json.user_login,
      json.plan_mass,
      json.plan_volume,
      json.fact_mass,
      json.fact_volume,
    );
  }

  public createTaskFuelingHistoryItem(json: TaskFuelingHistoryItemJson) {
    return new TaskFuelingHistoryItem(
      json.id,
      json.refueling_id,
      json.platform_id,
      json.reason_id,
      json.flight_number,
      json.date_time,
      json.aircraft_type,
      json.aircraft_number,
      json.task_number,
      json.task_status,
      json.task_state,
      json.user_login,
      json.anti_icing_fluid_id,
      json.plan_mass,
      json.plan_volume,
      json.fact_mass,
      json.fact_volume,
    );
  }

}
