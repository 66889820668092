import { Injectable, Output, EventEmitter } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

interface ExtendedOptions {
  bodyClass?: string;
  prop?: string;
  modalName?: string;
  callback?: () => void;
}

export interface EventMetadata {
  prop?: any;
  isShow?: boolean;
  component: any;
  inputData?: any;
  options?: NgbModalOptions & ExtendedOptions;
}

@Injectable()
export class ModalService {
  @Output() requestForModalWithComponent: EventEmitter<EventMetadata> = new EventEmitter();
  @Output() requestCloseModalByName = new EventEmitter();

  showWithComponent(component: any, inputData?: any, options?: NgbModalOptions & ExtendedOptions) {
    this.requestForModalWithComponent.emit({inputData, component, options, isShow: true});
  }

  closeModalByName(modalName: string) {
    this.requestCloseModalByName.emit(modalName);
  }
}
