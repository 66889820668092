export class Airline {
  constructor(
    public id: number,
    public iataCode: string,
    public name: string,
    public fullName: string,
    public contragentCode: string,
    public organizationCode: string,
    public itn: string,
    public iec: string,
  ) {
  }
}
