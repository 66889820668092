import {
  Component, ChangeDetectionStrategy, AfterViewInit, Input, Output, EventEmitter, ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDatepickerI18n, NgbDate, NgbDropdown,
  NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DataGridEditorComponent } from "@shared/components/data-grid-editor-component.interface";
import { NgbDateService } from "@shared/services/utility/ngb-date.service";
import moment from 'moment';

import { convertNgbDateToDate } from '../../../../utils/convert-ngb-date-to-date';
import {
  DatepickerI18n, CustomRussianDatepickerI18n
} from '../../services/custom-russioan-datepicker-i18n.service';
import { DateBoxInitializedEvent, DateBoxChangeEvent } from './date-box.model';

@Component({
  selector: 'app-date-box',
  templateUrl: './date-box.component.html',
  styleUrls: ['./date-box.component.scss'],
  providers: [DatepickerI18n, { provide: NgbDatepickerI18n, useClass: CustomRussianDatepickerI18n }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateBoxComponent implements DataGridEditorComponent, AfterViewInit {

  @Output() onAfterViewInit = new EventEmitter<DateBoxInitializedEvent>();

  @Output() onChange = new EventEmitter<DateBoxChangeEvent>();

  @ViewChild(NgbDropdown, { static: false }) dropdown: NgbDropdown;

  @ViewChild(NgbDatepicker, { static: false }) datepicker: NgbDatepicker;

  textValue: string;

  readonly defaultDateFormat = 'dd.MM y';

  dateFormat = this.defaultDateFormat;

  private _selectedDate: NgbDate;

  @Input() set selectedDate(value: NgbDate) {
    this._selectedDate = value;
    this.fireOnChange();
    this.setTextValue();
  }

  get selectedDate() {
    return this._selectedDate;
  }

  constructor(
    private datePipe: DatePipe,
    private ngbDateService: NgbDateService,
  ) {}

  ngAfterViewInit() {
    this.onAfterViewInit.emit({ instance: this });
  }

  open() {
    this.dropdown.open();
    this.datepicker.navigateTo(this.selectedDate);
  }

  onDateSelection(date: NgbDate) {
    this.selectedDate = date;
    this.dropdown.close();
  }

  isSelected(date: NgbDate) {
    return date.equals(this.selectedDate);
  }

  setTextValue() {
    if (!this.selectedDate) return;

    const selectedDate = convertNgbDateToDate(this.selectedDate);

    this.textValue = (
      this.datePipe.transform(selectedDate, this.dateFormat)
    );
  }

  inputChangeHandler(textValue: string) {
    // TODO date format for parsing should be taken from this.dateFormat
    const date = moment(textValue, 'DD.MM YYYY');

    this.selectedDate = new NgbDate(
      date.year(),
      date.month() + 1,
      date.date(),
    );
  }

  isToday(date: NgbDate) {
    return this.ngbDateService.isToday(date);
  }

  private fireOnChange() {
    this.onChange.emit({
      value: this.selectedDate,
      instance: this,
    });
  }
}
