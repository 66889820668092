import { VehicleTaskStatesEnum } from '../enum/vehicle-task-states.enum';
import { VehicleTaskStatusesEnum } from '../enum/vehicle-task-statuses.enum';
import { VehicleTasksTypesEnum } from '../enum/vehicle-tasks-types.enum';
import { VehicleTask } from './vehicle-task.interface';


export class VehicleTaskFilling implements VehicleTask {

  public readonly type = VehicleTasksTypesEnum.FILLING;

  constructor(
    public id: string,
    public airportId: number | null,
    public fillingPlanPointId: number | null,
    public fillingFactPointId: number | null,
    public vehicleId: string,
    public airportIataCode: string,
    public number: string,
    public localCreateDate: string,
    public driver: string | null,
    public status: VehicleTaskStatusesEnum,
    public state: VehicleTaskStatesEnum | null,
    public createdBy: string,
    public approvedByDriver: boolean,
    public planStartDate: string,
    public planFinishDate: string,
    public factStartDate: string | null,
    public factFinishDate: string | null,
    public fillingPlanStartDate: string | null,
    public fillingPlanFinishDate: string | null,
    public fillingFactStartDate: string | null,
    public fillingFactFinishDate: string | null,
    public planMass: number | null,
    public factMass: number | null,
    public planVolume: number | null,
    public factVolume: number | null,
  ) {
  }
}
