import { NgModule } from '@angular/core';
import { AircraftsCommonModule } from '../../aircrafts/common/aircrafts.common.module';
import { AirlinesCommonModule } from '../../airlines/common/airlines.common.module';
import { AntiIcingFluidsCommonModule } from '../../anti-icing-fluids/common/anti-icing-fluids.common.module';
import { PlatformsCommonModule } from '../../platforms/common/platforms.common.module';
import { VehiclesTasksStore } from '../../vehicle-tasks/common/stores/vehicles-tasks.store';
import { VehiclesTasksService } from '../../vehicle-tasks/common/vehicles-tasks.service';
import { WorkShiftModule } from '../../work-shift/work-shift.module';
import { FlightsService } from './flights.service';
import { FlightsFactory } from './models/flights.factory';
import { FlightsStore } from './stores/flights.store';


@NgModule({
  imports: [
    WorkShiftModule,
    AntiIcingFluidsCommonModule,
    PlatformsCommonModule,
    AirlinesCommonModule,
    AircraftsCommonModule,
  ],

  providers: [
    FlightsFactory,
    FlightsService,
    FlightsStore,
    VehiclesTasksStore,
    VehiclesTasksService,
  ],
})
export class FlightsCommonModule {
}
