import { VehicleTaskStates, VehicleTaskStatesEnum } from './vehicle-task-states.enum';


export enum VehicleTaskStatusesEnum {
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED_BY_DRIVER = 'REJECTED_BY_DRIVER',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  REJECTED_DRAFT_BY_DISPATCHER = 'REJECTED_DRAFT_BY_DISPATCHER',
  REJECTED_DRAFT_BY_SYSTEM = 'REJECTED_DRAFT_BY_SYSTEM',
  INTERRUPTED = 'INTERRUPTED',
}

export const VEHICLE_TASK_STATUSES_LABELS = {
  [VehicleTaskStatusesEnum.DRAFT]: 'Рекомендовано',
  [VehicleTaskStatusesEnum.NEW]: 'Новое',
  [VehicleTaskStatusesEnum.ACCEPTED]: 'Принято',
  [VehicleTaskStatusesEnum.REJECTED_BY_DRIVER]: 'Отклонено водителем',
  [VehicleTaskStatusesEnum.PROCESSING]: 'В работе',
  [VehicleTaskStatusesEnum.DONE]: 'Исполнено',
  [VehicleTaskStatusesEnum.CANCELED]: 'Отменено',
  [VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_DISPATCHER]: 'Отклонено диспетчером',
  [VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_SYSTEM]: 'Отклонено системой',
  [VehicleTaskStatusesEnum.INTERRUPTED]: 'Прервано',
};

// todo: возможно лучше сделать сервисом?
export class VehicleTaskStatuses {

  private static readonly order: VehicleTaskStatusesEnum[] = [
    VehicleTaskStatusesEnum.DRAFT,
    VehicleTaskStatusesEnum.NEW,
    VehicleTaskStatusesEnum.ACCEPTED,
    VehicleTaskStatusesEnum.REJECTED_BY_DRIVER,
    VehicleTaskStatusesEnum.PROCESSING,
    VehicleTaskStatusesEnum.DONE,
    VehicleTaskStatusesEnum.CANCELED,
    VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_DISPATCHER,
    VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_SYSTEM,

  ];

  public static less(first: VehicleTaskStatusesEnum, second: VehicleTaskStatusesEnum) {
    return VehicleTaskStatuses.diff(first, second) < 0;
  }

  public static greater(first: VehicleTaskStatusesEnum, second: VehicleTaskStatusesEnum) {
    return VehicleTaskStatuses.diff(first, second) > 0;
  }

  public static isCanFuelingCancel(status: VehicleTaskStatusesEnum, state: VehicleTaskStatesEnum | null) {
    if (status === VehicleTaskStatusesEnum.NEW || status === VehicleTaskStatusesEnum.ACCEPTED) {
      return true;
    }

    if (status === VehicleTaskStatusesEnum.PROCESSING && VehicleTaskStates.less(state, VehicleTaskStatesEnum.START_FUELING)) {
      return true;
    }

    return false;
  }

  public static isCanFillingRejected(
    status: VehicleTaskStatusesEnum,
    state: VehicleTaskStatesEnum,
  ) {
    return status === VehicleTaskStatusesEnum.NEW
      || status === VehicleTaskStatusesEnum.ACCEPTED
      || (status === VehicleTaskStatusesEnum.PROCESSING && state !== VehicleTaskStatesEnum.SMALL_FORM_MAINTENANCE)
      ;
  }

  public static isActive(status: VehicleTaskStatusesEnum) {
    return status === VehicleTaskStatusesEnum.NEW || status === VehicleTaskStatusesEnum.ACCEPTED || status === VehicleTaskStatusesEnum.PROCESSING;
  }

  private static diff(first: VehicleTaskStatusesEnum, second: VehicleTaskStatusesEnum) {
    const firstIndex = VehicleTaskStatuses.order.findIndex(status => status === first);
    const secondIndex = VehicleTaskStatuses.order.findIndex(status => status === second);

    return firstIndex - secondIndex;
  }
}
