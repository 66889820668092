import { Airport } from './airport';


export class Departure {
  constructor(
    public airport: Airport,
    public code: string | null,
    public planDateTime: string | null,
    public factDateTime: string | null,
  ) {
  }
}
