import { Injectable } from '@angular/core';
import { AirportJson } from '../json/airport.json';
import { Airport } from './airport';


@Injectable({ providedIn: 'root' })
export class AirportsModelsFactory {

  public createAirport(json: AirportJson): Airport {
    return new Airport(
      json.id,
      json.name,
      json.city_name,
      json.country_name,
      json.iata_code,
      json.icao_code,
      json.time_zone,
    );
  }

}
