import { Injectable, OnDestroy } from '@angular/core';
import { StoreCollection } from '@shared/classes/store.collection';
import * as moment from 'moment-timezone';
import { Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { WorkShiftService } from '../../../work-shift/work-shift.service';
import { FlightsService } from '../flights.service';
import { Flight } from '../models/flight';


@Injectable()
export class FlightsStore implements OnDestroy {

  private static CHANGES_POLLING_INTERVAL = 30000;

  public readonly flights = new StoreCollection<Flight>();

  private tickSubject = new Subject<void>();

  private start: moment.Moment;

  private finish: moment.Moment;

  private destroy = new Subject<void>();

  constructor(
    private flightsService: FlightsService,
    private workShiftService: WorkShiftService,
  ) {
    this.initWorkShifts();
    this.initCheckChanges();
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public lastValue() {
    return this.flights.lastValue();
  }

  public findById(id: string): Flight | null {
    const flights = this.flights.lastValue();
    return flights ? flights.get(id) || null : null;
  }

  public reloadFlight(id: string) {
    this
      .flightsService
      .getFlight(id)
      .pipe(
        takeUntil(this.destroy),
      )
      .subscribe(flight => this.setFlightsAndTick([flight]));
  }

  public tick() {
    return this.tickSubject.asObservable();
  }

  private initWorkShifts() {
    this
      .workShiftService
      .getActiveWorkShifts()
      .pipe(
        takeUntil(this.destroy),
        map(workShifts => this.workShiftService.getWorkShiftsDatesRange(workShifts)),
      )
      .subscribe(range => {
        this.start = range.start;
        this.finish = range.finish;
        this.loadFlights();
      });
  }

  private loadFlights() {
    this.flights.reset();

    this
      .flightsService
      .loadFlights(this.start, this.finish)
      .subscribe(flights => this.setFlightsAndTick(flights));
  }

  private initCheckChanges() {
    timer(FlightsStore.CHANGES_POLLING_INTERVAL, FlightsStore.CHANGES_POLLING_INTERVAL)
      .pipe(
        takeUntil(this.destroy),
        filter(() => !this.flights.isEmpty()),
        map(() => moment().subtract(FlightsStore.CHANGES_POLLING_INTERVAL, 'milliseconds')),
        // todo: пока нет поддержки на бэкенде, перезагружать полностью
        // switchMap(from => this.flightsService.loadFlightsChanges(from)),
        switchMap(() => this.flightsService.loadFlights(this.start, this.finish)),
      )
      .subscribe(flights => this.setFlightsAndTick(flights));
  }

  private setFlightsAndTick(flights: Flight[]) {
    if (flights.length > 0) {
      this.flights.rewrite(flights, false);
    }

    this.tickSubject.next();
  }
}
