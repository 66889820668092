import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class TimezoneService {

  private static TIMEZONE_OFFSET = /^[+-]?\d+:\d+$/;

  public static isValidTimezone(timezone: string): boolean {
    return moment.tz.zone(timezone) !== null || TimezoneService.TIMEZONE_OFFSET.test(timezone);
  }

  public setTimezone(timezone: string) {
    if (TimezoneService.isValidTimezone(timezone)) {
      this.setGlobalTimezone(timezone);
    } else {
      throw new Error(`Invalid timezone '${ timezone }'`);
    }
  }

  public getTimezone(): string {
    return moment().tz() || moment.tz.guess();
  }

  private setGlobalTimezone(rawTimezone: string) {
    if (TimezoneService.TIMEZONE_OFFSET.test(rawTimezone)) {
      // todo: удалить, когда будут указаны тацмзоны на бэкенде
      let timezone = rawTimezone[0] === '-' || rawTimezone[0] === '+'
        ? rawTimezone
        : `+${ rawTimezone }`;

      moment.tz.setDefault(this.getZoneByOffset(timezone));
    } else {
      moment.tz.setDefault(rawTimezone);
    }
  }

  private getZoneByOffset(offset: string): string | null {
    const names = moment.tz.names();

    for (let name of names) {
      if (moment.tz(name).format('Z') === offset) {
        return name;
      }
    }

    return null;
  }
}
