export enum FuelUnitsEnum {
  MT= 'MT',
  LT = 'LT',
  KG = 'KG',
}

export const FUEL_UNITS_LABELS = {
  [FuelUnitsEnum.MT]: 'т',
  [FuelUnitsEnum.LT]: 'л',
  [FuelUnitsEnum.KG]: 'кг',
};
