import { Injectable } from '@angular/core';
import { returnVoid } from '@shared/functions/return-void.function';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { TimezoneService } from '@shared/services/system/timezone.service';
import { UserContextService } from '@shared/services/system/user-context.service';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AircraftsStore } from '../../../widgets/aircrafts/common/aircrafts.store';
import { AirlinesStore } from '../../../widgets/airlines/common/airlines.store';
import { AirportsStore } from '../../../widgets/airports/common/airports.store';
import { PlatformsStore } from '../../../widgets/platforms/common/platforms.store';
import { VehiclesStore } from '../../../widgets/vehicles/common/stores/vehicles.store';
import { FillingPointsStore } from '../../../widgets/filling-points/filling-points.store';


@Injectable({
  providedIn: 'root',
})
export class StartupService {

  constructor(
    private airportsStore: AirportsStore,
    private userContextService: UserContextService,
    private currentAirportService: CurrentAirportService,
    private timezoneService: TimezoneService,
    private platformsStore: PlatformsStore,
    private airlinesStore: AirlinesStore,
    private vehiclesStore: VehiclesStore,
    private aircraftsStore: AircraftsStore,
    private fillingPointsStore: FillingPointsStore,
  ) {
  }

  public init(): Promise<void> {
    this.userContextService.refresh();
    return this
      .currentAirportService
      .load()
      .pipe(
        take(1),
        tap(airport => {
          // если доступных аэропортов нет, придёт null
          if (airport) {
            this.timezoneService.setTimezone(airport.time_zone);
          }
        }),
        switchMap(() => this.initDictionaries()),
      )
      .toPromise();
  }

  private initDictionaries(): Observable<void> {
    return forkJoin([
      this.airportsStore.init(),
      this.platformsStore.init(),
      this.airlinesStore.init(),
      this.vehiclesStore.init(),
      this.aircraftsStore.init(),
      this.fillingPointsStore.init(),
    ])
      .pipe(
        map(returnVoid),
      );
  }
}
