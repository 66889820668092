import {
  Component, ChangeDetectionStrategy, forwardRef, AfterViewInit,
  Input, Output, EventEmitter, ViewChild, ElementRef, HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataGridEditorComponent, DataGridEditorEvent } from "@shared/components/data-grid-editor-component.interface";
import { TextBoxBaseEvent, TextBoxKeyEvent } from './text-box.model';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextBoxComponent),
    multi: true,
  }],
})
export class TextBoxComponent implements DataGridEditorComponent, AfterViewInit, ControlValueAccessor {

  @Input() type: 'text' | 'password' | 'search' = 'text';

  @Input() placeholder: string;

  @Input() autofocus: boolean;

  @Input() showClearButton = false;

  @Input() cssClass: string;

  @Output() onAfterViewInit = new EventEmitter<DataGridEditorEvent>();

  @Output() onBlur = new EventEmitter<TextBoxBaseEvent>();

  @Output() onKeyDown = new EventEmitter<TextBoxKeyEvent>();

  @Output() onKeyUp = new EventEmitter<TextBoxKeyEvent>();

  @Output() onClear = new EventEmitter<void>();

  @ViewChild('inputElement', { static: false }) inputElementRef: ElementRef<HTMLInputElement>;

  get value() {
    return this._value;
  }

  set value(value: string | number) {
    if (value === this._value) return;

    this._value = value;
    this.propagateChange(this.value);
  }

  private _value: string | number;

  ngAfterViewInit() {
    this.onAfterViewInit.emit({ instance: this });
  }

  writeValue(value: string | number) {
    if (value === undefined) return;

    this.value = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  focus() {
    this.inputElementRef.nativeElement.focus();
  }

  changeHandler(event: Event) {
    const element = event.target as HTMLInputElement;

    this.value = element.value;
    this.propagateChange(this.value);
  }

  blurHandler() {
    this.onBlur.emit({ instance: this });
    this.propagateTouch();
  }

  keyDownHandler(event: KeyboardEvent) {
    this.onKeyDown.emit({
      instance: this,
      event,
    });
  }

  keyUpHandler(event: KeyboardEvent) {
    this.changeHandler(event);

    this.onKeyUp.emit({
      instance: this,
      event,
    });
  }

  private propagateChange(_: any) {};

  private propagateTouch() {};
}
