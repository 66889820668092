import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FillingPointsService } from './filling-points.service';
import { FillingPoint } from './models/filling-point';
import { returnVoid } from '@shared/functions/return-void.function';


@Injectable({ providedIn: 'root' })
export class FillingPointsStore implements OnDestroy {

  private destroy = new Subject<void>();

  private indexById = new Map<number, FillingPoint>();

  private fillingPointsSubject = new BehaviorSubject<FillingPoint[]>([]);

  constructor(
    private fillingPointsService: FillingPointsService,
  ) {
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public init(): Observable<void> {
    return this
      .fillingPointsService
      .loadFillingPoints()
      .pipe(
        tap(fillingPoints => this.set(fillingPoints)),
        map(returnVoid),
      );
  }

  public get(): FillingPoint[] {
    return this.fillingPointsSubject.getValue();
  }

  public set(fillingPoints: FillingPoint[]): void {
    this.indexById = new Map<number, FillingPoint>(fillingPoints.map(fillingPoint => [fillingPoint.id, fillingPoint]));
    this.fillingPointsSubject.next(fillingPoints);
  }

  public findById(id: number): FillingPoint | null {
    return this.indexById.get(id) || null;
  }

}
