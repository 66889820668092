import {
  Component, ChangeDetectionStrategy, forwardRef, AfterViewInit, Input, Output, EventEmitter,
  HostBinding, HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ToggleBoxBaseEvent } from './toggle-box.model';

@Component({
  selector: 'app-toggle-box',
  templateUrl: './toggle-box.component.html',
  styleUrls: ['./toggle-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleBoxComponent),
    multi: true,
  }],
})
export class ToggleBoxComponent implements AfterViewInit, ControlValueAccessor {

  @Input() value = false;

  @HostBinding('class._disabled') @Input() disabled = false;

  @Output() onAfterViewInit = new EventEmitter<ToggleBoxBaseEvent>();

  @Output() onChange = new EventEmitter<ToggleBoxBaseEvent>();

  @HostListener('click') onClickHandler() {
    if (this.disabled) return;

    this.value = !this.value;

    this.onChange.emit({
      instance: this,
      value: this.value,
    });

    this.propagateChange(this.value);
  }

  ngAfterViewInit() {
    this.onAfterViewInit.emit({ instance: this, value: this.value });
  }

  writeValue(value: boolean) {
    if (value === undefined) return;

    this.value = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  private propagateChange(_: any) {};

  private propagateTouch() {};
}
