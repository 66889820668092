import { Airport } from './airport';


export class Arrival {
  constructor(
    public airport: Airport | null,
    public dateTime: string | null,
    public sign: string | null,
    public transitSign: string | null,
  ) {
  }
}
