import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FlightsService } from 'src/app/flights-old/services/flights.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {

  constructor(
    private flightsService: FlightsService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  public selectAircraft(aircraft: string) {
    console.log(aircraft);
  }

}
