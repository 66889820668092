import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../app.shared';
import { AntiIcingFluidsCommonModule } from '../../anti-icing-fluids/common/anti-icing-fluids.common.module';
import { FlightsCommonModule } from '../../flights/common/flights.common.module';
import { WorkShiftModule } from '../../work-shift/work-shift.module';
import { VehiclesTasksModelsFactory } from './models/vehicles-tasks-models.factory';
import { VehiclesTasksStore } from './stores/vehicles-tasks.store';
import { VehiclesTasksService } from './vehicles-tasks.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FlightsCommonModule,
    WorkShiftModule,
    AntiIcingFluidsCommonModule,
  ],

  providers: [
    VehiclesTasksModelsFactory,
    VehiclesTasksService,
    VehiclesTasksStore,
  ],
})
export class VehiclesTasksCommonModule {
}
