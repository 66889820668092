export class VehicleRemainingFuel {
  constructor(
    public volume: number,
    public mass: number | null = null,
    public name: string | null = null,
  ) {
  }

  public get density(): number {
    if (this.mass === null) {
      return 1;
    }

    return this.mass / this.volume;
  }

  public isEqual(remainingFuel: VehicleRemainingFuel) {
    return remainingFuel.volume === this.volume && remainingFuel.mass === this.mass && remainingFuel.name === this.name;
  }
}
