import { StoreEntity } from '@shared/classes/store.collection';
import { DateTimeRange } from '@shared/models/date-time-range';
import { Aircraft } from '../../../aircrafts/common/models/aircraft';
import { Airline } from '../../../airlines/common/models/airline';
import { AntiIcingFluidsEnum } from '../../../anti-icing-fluids/common/enums/anti-icing-fluids.enum';
import { Platform } from '../../../platforms/common/models/platform';
import { FlightDestinationTypesEnum } from '../enums/flight-destination-types.enum';
import { FlightScheduleStatusesEnum } from '../enums/flight-schedule-statuses.enum';
import { FlightSourcesEnum } from '../enums/flight-sources.enum';
import { FlightStatusesEnum } from '../enums/flight-statuses.enum';
import { Arrival } from './arrival';
import { Departure } from './departure';
import { FlightFuel } from './flight-fuel';


export class Flight implements StoreEntity {
  constructor(
    public id: string,
    public source: FlightSourcesEnum,
    public status: FlightStatusesEnum,
    public scheduleFlightStatus: FlightScheduleStatusesEnum,
    public number: string,
    public airline: Airline | null,
    public aircraft: Aircraft | null,
    public fuel: FlightFuel,
    public tgoPlan: DateTimeRange | null,
    public tgoFact: DateTimeRange | null,
    public tasksCount: number,
    public destination: string | null,
    public antiIcingFluid: AntiIcingFluidsEnum | null,
    public departure: Departure | null,
    public arrival: Arrival | null,
    public reference: string | null,
    public orderId: string | null,
    public accepterId: string | null,
    public platform: Platform | null,
    public serviceType: string | null,
    public kvys: string | null,
    public terminalDeparture: string | null,
    public internationalDomesticSign: FlightDestinationTypesEnum | null,
    public internationalDomestic2Sign: string | null,
    public srDat: string | null,
    public pNros: string | null,
    public inRegistry: boolean,
  ) {
  }

  public clone(): Flight {
    return new Flight(
      this.id,
      this.source,
      this.status,
      this.scheduleFlightStatus,
      this.number,
      this.airline,
      this.aircraft,
      this.fuel,
      this.tgoPlan,
      this.tgoFact,
      this.tasksCount,
      this.destination,
      this.antiIcingFluid,
      this.departure,
      this.arrival,
      this.reference,
      this.orderId,
      this.accepterId,
      this.platform,
      this.serviceType,
      this.kvys,
      this.terminalDeparture,
      this.internationalDomesticSign,
      this.internationalDomestic2Sign,
      this.srDat,
      this.pNros,
      this.inRegistry,
    );
  }
}
