export class DateTimeRange {
  constructor(
    public start: string | null,
    public finish: string | null,
  ) {
  }

  public clone() {
    return new DateTimeRange(this.start, this.finish);
  }
}
