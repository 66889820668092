import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Airport } from "@shared/models/airport.model";

@Component({
  selector: 'app-airport-selector',
  templateUrl: './nav-bar-airport.component.html',
  styleUrls: ['./nav-bar-airport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarAirportComponent {
  @Input() airports: Airport[];
  @Input() selectedAirport: Airport;
  @Output() onSelect = new EventEmitter<Airport>();

  changeAirport(airport: Airport) {
    this.onSelect.emit(airport);
  }
}
