import {
  Component, ChangeDetectionStrategy, forwardRef, OnChanges,
  AfterViewInit, Input, Output, EventEmitter, SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataGridEditorComponent, DataGridEditorEvent } from "@shared/components/data-grid-editor-component.interface";
import { Observable, isObservable, of } from 'rxjs';

import { SelectBoxItem, SelectBoxInitializedEvent } from './select-box.model';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectBoxComponent),
    multi: true,
  }],
})
export class SelectBoxComponent implements DataGridEditorComponent, OnChanges, AfterViewInit, ControlValueAccessor {

  @Input() items: SelectBoxItem[] | Observable<SelectBoxItem[]>;

  @Input() currentValue: string | number;

  @Output() onAfterViewInit = new EventEmitter<SelectBoxInitializedEvent>();

  items$: Observable<SelectBoxItem[]>;

  caption: string;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.items) return;

    this.items$ = isObservable(this.items) ? this.items : of(this.items);
  }

  ngAfterViewInit() {
    this.onAfterViewInit.emit({ instance: this });
  }

  writeValue(value: string | number) {
    if (value === undefined) return;

    this.currentValue = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  select(item: SelectBoxItem) {
    this.caption = item.name;
    this.currentValue = item.value;
    this.propagateChange(this.currentValue);
  }

  onBlur() {
    this.propagateTouch();
  }

  private propagateChange(_: any) {};

  private propagateTouch() {};
}
