import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {

  @Input() text: string;

  @Input() size: 'sm' | 'lg';

  @Input() items: {
    text?: string;
    click?: (event: MouseEvent) => void
  }[];

}
