import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AirlineJson } from './json/airline.json';
import { Airline } from './models/airline';
import { AirlinesModelsFactory } from './models/airlines-models.factory';


@Injectable({ providedIn: 'root' })
export class AirlinesService {

  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private currentAirportService: CurrentAirportService,
    private airlinesModelsFactory: AirlinesModelsFactory,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public load(): Observable<Airline[]> {
    return this
      .httpClient
      .get<AirlineJson[]>(`${this.apiUrl}/v1/catalog/contractors`)
      .pipe(
        map(jsonItems => jsonItems.map(json => this.airlinesModelsFactory.createAirline(json))),
      );
  }

}
