import { Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { RangeDateBoxValue } from "@shared/components/range-date-box/range-date-box.model";
import { DateTimeFormatter } from "@shared/services/utility/date-time-formatter.service";
import * as moment from "moment-timezone";
import { Moment } from "moment-timezone";
import { convertNgbDateToDate } from "../../../../utils/convert-ngb-date-to-date";

@Injectable({
  providedIn: "root",
})
export class NgbDateService {

  constructor(private formatter: DateTimeFormatter) {

  }

  getCurrentNgbDate(): NgbDate {
    return this.convertMomentToNgbDate(moment());
  }

  isToday(date: NgbDate): boolean {
    return date && date.equals(this.getCurrentNgbDate());
  }

  convertDateToNgbDate(date: Date): NgbDate {
    return this.convertMomentToNgbDate(moment(date));
  }

  convertMomentToNgbDate(m: Moment): NgbDate {
    return new NgbDate(
      m.year(),
      m.month() + 1,
      m.date(),
    );
  }

  convertNgbDateToDate(date: NgbDate) {
    return convertNgbDateToDate(date);
  }

  convertNgbDateToMoment(date: NgbDate) {
    return moment(this.convertNgbDateToDate(date));
  }

  rangeDateBoxValueToMomentTuple(inputRange: RangeDateBoxValue): Moment[] {
    return [
      inputRange.from && this.convertNgbDateToMoment(inputRange.from).startOf("day"),
      inputRange.to && this.convertNgbDateToMoment(inputRange.to).endOf("day"),
    ];
  }

  rangeDateBoxValueToISOStringTuple(inputRange: RangeDateBoxValue): string[] {
    return this.rangeDateBoxValueToMomentTuple(inputRange)
      .map(m => this.formatter.toFullIso(m));
  }
}
