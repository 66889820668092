export enum AntiIcingFluidsEnum {
  NO = 0, // todo: убрать, когда будет на бэке поддержано
  I_AND_M = 1,
  I = 2,
}

export const ANTI_ICING_FLUIDS_LABELS = {
  [AntiIcingFluidsEnum.NO]: 'Нет', // todo: убрать, когда будет на бэке поддержано
  [AntiIcingFluidsEnum.I_AND_M]: 'И-М',
  [AntiIcingFluidsEnum.I]: 'И',
};
