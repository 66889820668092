import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";

@Pipe({
  name: "moment",
})
export class MomentPipe implements PipeTransform {
  transform(value: Moment, ...args: any[]): string {
    if (moment.isMoment(value)) {
      return value.format(args[0]);
    }
    return null;
  }
}
