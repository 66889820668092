export class Platform {
  constructor(
    public id: number,
    public name: string,
    public airportIataCode: string,
    public hasDispenser: boolean | null,
    public latitude: number | null,
    public longitude: number | null,
  ) {
  }
}
