import { Injectable } from '@angular/core';
import { TimezoneService } from '@shared/services/system/timezone.service';
import { unitOfTime } from 'moment';
import * as moment from 'moment-timezone';


@Injectable({
  providedIn: 'root',
})
export class DateTimeFormatter {
  public static readonly defaultFormat = 'DD.MM.YYYY HH:mm';

  public format(input: any, formatString?: string, timezone?: string): string {
    const inputMoment = moment.isMoment(input) ? input.clone() : moment(input);

    if (formatString === undefined || formatString === null) {
      formatString = DateTimeFormatter.defaultFormat;
    }

    if (formatString === '' || input === '' || input === undefined || input === null) {
      return '';
    }

    return timezone
      ? this.formatWithTimezone(inputMoment, formatString, timezone)
      : this.formatWithoutTimezone(inputMoment, formatString);
  }

  public toFullIso(input: any, timezone: string | null = 'UTC') {
    return this.format(input, 'YYYY-MM-DDTHH:mm:ss', timezone) + 'Z';
  }

  public toFullIsoWithMilliseconds(input: any, timezone: string | null = 'UTC') {
    // todo: удостовериться в правильном варианте
    // return this.format(input, "YYYY-MM-DDTHH:mm:ss.SSS", timezone) + "Z";
    return this.format(input, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timezone);
  }

  // todo: возможно методу тут не место, решить на этапе унификации работы с датамми
  public getTimeDifference(startDate: moment.MomentInput | null, finishDate: moment.MomentInput | null, outputFormat = 'HH:mm') {
    const diff = moment.utc(moment.utc(finishDate).diff(startDate));
    if (!startDate && !finishDate) return moment().startOf('day').format(outputFormat);
    return diff.format(outputFormat);
  }

  public minDateFromArray<T extends moment.MomentInput>(dates: T[]) {
    if (dates.length === 0) {
      return null;
    }

    const sortedDates = [...dates].sort((a: T, b: T) => moment(a).diff(b));

    return sortedDates[0];
  }

  public maxDateFromArray<T extends moment.MomentInput>(dates: T[]) {
    if (dates.length === 0) {
      return null;
    }

    const sortedDates = [...dates].sort((a: T, b: T) => moment(b).diff(a));

    return sortedDates[0];
  }

  public minDate<T extends moment.MomentInput>(first: T, second: T) {
    return this.minDateFromArray<T>([first, second]);
  }

  public maxDate<T extends moment.MomentInput>(first: T, second: T) {
    return this.maxDateFromArray<T>([first, second]);
  }

  public normalizeByLeft(input: moment.MomentInput, unit: unitOfTime.DurationConstructor, divider: number) {
    const momentDate = moment(input);
    const currentValue = momentDate.get(unit);

    return momentDate.set(unit, currentValue - currentValue % divider);
  }

  public normalizeByRight(input: moment.MomentInput, unit: unitOfTime.DurationConstructor, divider: number) {
    const momentDate = moment(input);
    const currentValue = momentDate.get(unit);

    return momentDate.set(unit, currentValue + (divider - currentValue % divider));
  }

  private formatWithoutTimezone(inputMoment: moment.Moment, formatString: string) {
    return inputMoment.format(formatString);
  }

  private formatWithTimezone(inputMoment: moment.Moment, formatString: string, timezone: string) {
    if (TimezoneService.isValidTimezone(timezone)) {
      return inputMoment.tz(timezone).format(formatString);
    } else {
      throw new Error(`Invalid timezone '${ timezone }'`);
    }
  }
}
