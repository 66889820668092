import { DatePipe as AngularDatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: "appGpnDate"
})
export class GpnDatePipe implements PipeTransform {
  constructor(private datePipe: AngularDatePipe) {
  }

  public transform(value: any, format: string) {
    const offset = moment().format('Z');
    return this.datePipe.transform(value, format, offset);
  }
}
