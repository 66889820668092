import { Platform } from '@shared/models/platform';
import { FlightDestinationTypesEnum } from '../../widgets/flights/common/enums/flight-destination-types.enum';
export enum FlightStatus {
  AT_WORK = 'atWork',
  COMPLETED = 'completed',
  BLOCKED = 'blocked',
  ASSIGNED = 'assigned',
  NO_JOBS = 'noJobs',
  RECOMMENDED = 'recommended',
}

export const FlightStatusNameMap: {[key: string]: string} = {
  [FlightStatus.AT_WORK]: 'В работе',
  [FlightStatus.COMPLETED]: 'Заправлен',
  [FlightStatus.BLOCKED]: 'Заблокирован',
  [FlightStatus.ASSIGNED]: 'Задание назначено',
  [FlightStatus.NO_JOBS]: 'Нет заданий',
  [FlightStatus.RECOMMENDED]: 'Есть рекомендации',
}

export const FlightStatusDirectValueMap: {[key: string]: string} = {
  [FlightStatus.AT_WORK]: '3',
  [FlightStatus.COMPLETED]: '4',
  [FlightStatus.BLOCKED]: '2',
  [FlightStatus.ASSIGNED]: '1',
  [FlightStatus.NO_JOBS]: '0',
  [FlightStatus.RECOMMENDED]: '6',
}

export enum FlightScheduledStatus {
  SCHEDULED = 'scheduled',
  DEPARTED = 'departed',
  ARRIVAL = 'arrival',
  CANCELED = 'canceled',
  REGISTRATION = 'registration',
  DELAYED = 'delayed',
  CHANGED = 'changed',
}

export const FlightScheduledStatusNameMap: {[key: string]: string} = {
  [FlightScheduledStatus.SCHEDULED]: 'Запланирован',
  [FlightScheduledStatus.DEPARTED]: 'Улетел',
  [FlightScheduledStatus.ARRIVAL]: 'Прилетел',
  [FlightScheduledStatus.CANCELED]: 'Отменен',
  [FlightScheduledStatus.REGISTRATION]: 'Регистрация',
  [FlightScheduledStatus.DELAYED]: 'Задержан',
  [FlightScheduledStatus.CHANGED]: 'Перенесен',
}

export enum FlightSources {
    BLOCKCHAIN = "blockchain",
    DISPTCHER = "dispatcher",
    ONEC = "onec",
    MASH = "mash"
}

export interface TableFlightsData {
  _yesterday: Flight[];
  today: Flight[];
  tomorrow: Flight[];
}

export interface TableScrollPositions {
  _yesterdayHeight: number;
  todayHeight: number;
  tomorrowHeight: number;
}

export class AntiIcingFluid {
  id: number;
  name: string;
}

export class Aircraft {
  id?: number;
  type?: string; // <Тип ВС>
  number?: string; // <№ борта>
}

export class Fuel {
  quantity: number; //<Залито> Количество ГСМ, кг
  unit: string; // IATA-код единицы измерения продукта(ГСМ / услуга)
}

export class Flight { //Таблица Рейсов (Заявок)

  id?: string; //идентификатор рейса
  order_id?: string; //id заявки - в swagger он string

  reference?: string; // <Номер заявки> Реквизиты заявки в виде "DD.MM.YY N......" или иной внешний идентификатор
  status?: FlightStatus; //<Статус заявки> - из Order Нет заданий(?) | Задание назначено (Assigned) | Заблокирован (Blocked) | В Работе(AtWork) | Исполнен (Completed)
  destination?: string; //<Направление>
  arrival_airport_iata_code?: string; //<Аэропорт назначения> IATA-код аэропорта прилета
  arrival_airport_name?: string; //<Аэропорт назначения> читаемое значение
  airline_code?: string; //<Авиакомпания> (IATA-код авиакомпании)
  airline_name?: string; //<Авиакомпания> (Наименование авиакомпании)
  anti_icing_fluid?: AntiIcingFluid;
  number?: string; //<Рейс> Номер рейса
  aircraft?: Aircraft;
  parking_number?: string; // <Стоянка> Перон (Номер стоянки ВС)
  arrival_date_time?: string; //<Время прилета> Плановые дата и время прилета по местному времени аэропорта, в виде даты - времени по UTC "YYYY-MM-DDTHH:mm:SSZ"(ISO 8601)
  departure_date_time?: string; //<Время вылета> Плановые дата и время вылета по местному времени аэропорта, в виде даты - времени по UTC "YYYY-MM-DDTHH:mm:SSZ"(ISO 8601)

  tgo_start_plan_date_time?: string; //<Время начала> Начало заправки
  tgo_start_fact_date_time?: string; // <Факт начала>
  tgo_finish_plan_date_time?: string; //<Время конца> Окончание заправки
  tgo_finish_fact_date_time?: string; //<Факт конца>

  duration_plan?: string; //Продолжительность план
  duration_fact?: string; //Продолжительность факт
  schedule_flight_status?: string;

  fuel?: Fuel;

  fuel_forecast?: Fuel;

  fuel_accurate_forecast?: Fuel;

  fuel_fact?: Fuel;

    //---------
  departure_airport_iata_code?: string; // IATA код аэропорта вылета
  accepter_id?: string; //ID КВС или представителя авиакомпании, ответственного за приемку топлива
  created_date_time?: string; //Дата и время создания объекта в виде "YYYY-MMDDTHH:mm:SSZ" (ISO 8601)
  updated_date_time?: string; //Дата и время изменения объекта в виде "YYYY-MMDDTHH:mm:SSZ" (ISO 8601)
  tasks_count?: number;
  source?: FlightSources;

  departure_fact_date_time?: string;

  tasks_status?: 'started' | 'partially_completed' | 'all_completed';
  platform?: Platform;

  international_domestic_sign: FlightDestinationTypesEnum | null;
}
