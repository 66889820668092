import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GpnGanttChartCommonModule } from '@gpn/ngx-uikit';
import { SharedModule } from '../../../app.shared';
import { VehiclesTasksCommonModule } from '../../vehicle-tasks/common/vehicles-tasks.common.module';
import { VehicleNamePipe } from './pipes/vehicle-name.pipe';
import { VehiclesListPipe } from './pipes/vehicles-list.pipe';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GpnGanttChartCommonModule,
    VehiclesTasksCommonModule,
  ],

  declarations: [
    VehiclesListPipe,
    VehicleNamePipe,
  ],

  providers: [
    VehicleNamePipe,
  ],

  exports: [
    VehiclesListPipe,
    VehicleNamePipe,
  ],
})
export class VehiclesCommonModule {
}
