import { Injectable } from '@angular/core';
import { Airport } from '@shared/models/airport.model';
import { CurrentAirportStorageService } from '@shared/services/business/current-airport-storage.service';
import { AirportsService } from '@shared/services/dictionary/airports.service';
import { ApplicationControlService } from '@shared/services/system/application-control.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentAirportService {
  public currentAirport: Airport;

  constructor(
    private airportsService: AirportsService,
    private storageService: CurrentAirportStorageService,
    private applicationControlService: ApplicationControlService,
  ) {
  }

  public get currentIATA() : string {
    return this.currentAirport && this.currentAirport.iata_code;
  };

  public load(): Observable<Airport> {
    return this.storageService.load()
      .pipe(tap(airport => {
        return this.currentAirport = airport;
      }));
  }

  public setCurrentAirport(airport: Airport) {
    this.storageService.save(airport)
      .subscribe(() => this.applicationControlService.reload());
  }

  public setCurrentAirportByIATA(code: string) {
    this.airportsService.getAirportByIATA(code)
      .subscribe(airport => this.setCurrentAirport(airport));
  }
}
