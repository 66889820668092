import { Fuel } from './fuel';


export class FlightFuel {
  constructor(
    public dispatcherPlan: Fuel | null,
    public forecastPlan: Fuel | null,
    public accurateForecastPlan: Fuel | null,
    public plan: Fuel | null,
    public fact: Fuel | null,
  ) {
  }
}
