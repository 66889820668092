import { Injectable } from '@angular/core';
import { returnVoid } from '@shared/functions/return-void.function';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AircraftsService } from './aircrafts.service';
import { Aircraft } from './models/aircraft';


@Injectable({ providedIn: 'root' })
export class AircraftsStore {

  private aircraftsSubject = new BehaviorSubject<Aircraft[]>([]);

  private indexById = new Map<number, Aircraft>();

  private indexByTypeAndNumber = new Map<string, Aircraft>();

  private types: string[] = [];

  constructor(
    private aircraftsService: AircraftsService,
  ) {
  }

  public init(): Observable<void> {
    return this
      .aircraftsService
      .load()
      .pipe(
        tap(aircrafts => this.set(aircrafts)),
        map(returnVoid),
      );
  }

  public get(): Aircraft[] {
    return this.aircraftsSubject.getValue();
  }

  public getTypes(number: string | null): string[] {
    const types = new Set<string>();
    this
      .get()
      .forEach(aircraft => {
        if (aircraft.type && (!number || aircraft.number === number)) {
          types.add(aircraft.type);
        }
      });

    return [...types];
  }

  public getNumbers(type: string | null = null): string[] {
    const numbers = new Set<string>();
    this
      .get()
      .forEach(aircraft => {
        if (aircraft.number && (!type || aircraft.type === type)) {
          numbers.add(aircraft.number);
        }
      });

    return [...numbers];
  }

  public findById(id: number): Aircraft | null {
    return this.indexById.get(id) || null;
  }

  public findByTypeAndNumber(type: string | null, number: string | null): Aircraft | null {
    return this.indexByTypeAndNumber.get(this.getIndexKeyForTypeAndNumber(type, number)) || null;
  }

  public changes(): Observable<Aircraft[]> {
    return this.aircraftsSubject.asObservable();
  }

  public set(aircrafts: Aircraft[]): void {
    this.refreshIndexes(aircrafts);
    this.refreshTypes(aircrafts);
    this.aircraftsSubject.next(aircrafts);
  }

  private refreshIndexes(aircrafts: Aircraft[]): void {
    const indexByTypeAndNumber = new Map<string, Aircraft>();
    const indexById = new Map<number, Aircraft>();

    aircrafts.forEach(aircraft => {
      if (!aircraft.type && !aircraft.number) {
        return;
      }

      indexByTypeAndNumber.set(this.getIndexKeyForTypeAndNumber(aircraft.type, aircraft.number), aircraft);
      indexById.set(aircraft.id, aircraft);
    });

    this.indexByTypeAndNumber = indexByTypeAndNumber;
    this.indexById = indexById;
  }

  private refreshTypes(aircrafts: Aircraft[]): void {
    const types = new Set<string>(aircrafts.map(aircraft => aircraft.type).filter(type => !!type));
    this.types = [...types];
  }

  private getIndexKeyForTypeAndNumber(type: string | null, number: string | null): string {
    return `${ type } ${ number }`.toLocaleLowerCase();
  }

}
