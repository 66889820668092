export class Airport {
  constructor(
    public id: number,
    public name: string,
    public cityName: string,
    public countryName: string,
    public iataCode: string,
    public icaoCode: string,
    public timeZone: string,
  ) {
  }
}
