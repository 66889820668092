import { FuelDataSourcesEnum } from '../enums/fuel-data-sources.enum';
import { FuelUnitsEnum } from '../enums/fuel-units.enum';


export class Fuel {
  constructor(
    public unit: FuelUnitsEnum,
    public quantity: number | null,
    public dataSource: FuelDataSourcesEnum | null,
  ) {
  }
}
