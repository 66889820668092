import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbActiveModal,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/components/button/button.component';
import { DateBoxComponent } from '@shared/components/date-box/date-box.component';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { InputComponent } from '@shared/components/input/input.component';
import { ListComponent } from '@shared/components/list/list.component';
import { LogoComponent } from '@shared/components/logo/logo.component';
import { RangeDateBoxComponent } from '@shared/components/range-date-box/range-date-box.component';
import { SelectBoxComponent } from '@shared/components/select-box/select-box.component';
import { StaticTextComponent } from '@shared/components/static-text/static-text.component';
import { TextBoxComponent } from '@shared/components/text-box/text-box.component';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { ToggleBoxComponent } from '@shared/components/toggle-box/toggle-box.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { HrefToRouterLinkDirective } from '@shared/directives/href-to-router-link.directive';
import { AirportNamePipe } from '@shared/pipes/airport-name.pipe';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { GpnDatePipe } from '@shared/pipes/gpn-date.pipe';
import { MomentPipe } from '@shared/pipes/moment.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { SincePipe } from '@shared/pipes/since.pipe';
import { TimeDifferencePipe } from '@shared/pipes/time-difference.pipe';
import { ModalService } from '@shared/services/modal.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ModalComponent } from './modal/modal.component';

const BOOTSTRAP = [NgbTabsetModule, NgbDropdownModule, NgbDatepickerModule, NgbAlertModule, NgbTooltipModule, NgbPaginationModule, NgbModalModule];

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ...BOOTSTRAP,
  PerfectScrollbarModule,
  DragDropModule,
];
const PIPES = [
  MomentPipe,
  GpnDatePipe,
  SafePipe,
  FormatDatePipe,
  TimeDifferencePipe,
  AirportNamePipe,
  SincePipe,
];

const PROVIDERS = [
  DatePipe,
  ModalService,
  NgbActiveModal,
  { provide: 'window', useFactory: () => window }
];

const COMPONENTS = [
  InputComponent,
  ButtonComponent,
  DropdownComponent,
  DateBoxComponent,
  RangeDateBoxComponent,
  SelectBoxComponent,
  TextBoxComponent,
  ToggleBoxComponent,
  LogoComponent,
  ToastComponent,
  ListComponent,
  ModalComponent,
  StaticTextComponent,
];


const DIRECTIVES = [
  HrefToRouterLinkDirective,
  AutofocusDirective,
];


@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  providers: [PROVIDERS],
  entryComponents: [],
})
export class SharedModule {
}
