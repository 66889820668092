import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

enum Message {
    required = "Обязательное поле",
    email = "Неверный адрес электронной почты",
}

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit, OnDestroy {
  public isValid: any;
  public hasValue = false;
  public message: string;

  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() autocomplete: string;
  @Input() type = "text";
  @Input() size: "lg" | "sm";
  @Input() disabled: any;
  @Input() name: string;

  private onDestroy: Subject<void> = new Subject();

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(data => {
      this.hasValue = String(data).length > 0;
    });

    this.control.statusChanges.pipe(takeUntil(this.onDestroy)).subscribe(value => {
      this.setMessage();
      this.isValid = value === "VALID";
    });
  }

  setMessage() {
    const key = this.control.errors && Object.keys(this.control.errors)[0];
    this.message = Message[key];
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
