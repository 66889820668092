import { Component, OnInit } from "@angular/core";
import { KeycloakService } from "@shared/services/keycloak.service";

@Component({
  selector: "app-nav-bar-user",
  templateUrl: "./nav-bar-user.component.html",
  styleUrls: ["./nav-bar-user.component.scss"],
})
export class NavBarUserComponent {

  constructor(
    private keycloakService: KeycloakService,
  ) {
  }

  public async onLogout() {
    await this.keycloakService.logout();
  }

  public userFullName(): string {
    return this.keycloakService.getUserFullName()
  }

}
