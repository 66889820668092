import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as Keycloak_ from "keycloak-js";
export const Keycloak = Keycloak_;
import {
    ExcludedUrl,
    ExcludedUrlRegex,
    KeycloakOptions,
    KeycloakEvent,
    KeycloakEventType
} from "../models/keycloak.model";
import { environment } from "@environments/environment";

@Injectable()
export class KeycloakService {
  private _instance: Keycloak.KeycloakInstance;
  private _userProfile: Keycloak.KeycloakProfile;
  private _enableBearerInterceptor: boolean;
  private _silentRefresh: boolean;
  private _loadUserProfileAtStartUp: boolean;
  private _bearerPrefix: string;
  private _authorizationHeaderName: string;
  private _excludedUrls: ExcludedUrlRegex[];
  private _keycloakEvents$: Subject<KeycloakEvent> = new Subject<KeycloakEvent>();

  private bindsKeycloakEvents(): void {
    this._instance.onAuthError = errorData => {
      this._keycloakEvents$.next({
        args: errorData,
        type: KeycloakEventType.OnAuthError,
      });
    };

    this._instance.onAuthLogout = () => {
      this._keycloakEvents$.next({ type: KeycloakEventType.OnAuthLogout });
    };

    this._instance.onAuthRefreshSuccess = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshSuccess,
      });
    };

    this._instance.onAuthRefreshError = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshError,
      });
    };

    this._instance.onAuthSuccess = () => {
      this._keycloakEvents$.next({ type: KeycloakEventType.OnAuthSuccess });
    };

    this._instance.onTokenExpired = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnTokenExpired,
      });
    };

    this._instance.onReady = authenticated => {
      this._keycloakEvents$.next({
        args: authenticated,
        type: KeycloakEventType.OnReady,
      });
    };
  }

  private loadExcludedUrls(bearerExcludedUrls: (string | ExcludedUrl)[]): ExcludedUrlRegex[] {
    const excludedUrls: ExcludedUrlRegex[] = [];
    for (const item of bearerExcludedUrls) {
      let excludedUrl: ExcludedUrlRegex;
      if (typeof item === "string") {
        excludedUrl = { urlPattern: new RegExp(item, "i"), httpMethods: [] };
      } else {
        excludedUrl = {
          urlPattern: new RegExp(item.url, "i"),
          httpMethods: item.httpMethods,
        };
      }
      excludedUrls.push(excludedUrl);
    }
    return excludedUrls;
  }

  private initServiceValues({
        enableBearerInterceptor = true,
        loadUserProfileAtStartUp = true,
        bearerExcludedUrls = [],
        authorizationHeaderName = "Authorization",
        bearerPrefix = "bearer",
        initOptions,
    }: KeycloakOptions): void {
    this._enableBearerInterceptor = enableBearerInterceptor;
    this._loadUserProfileAtStartUp = loadUserProfileAtStartUp;
    this._authorizationHeaderName = authorizationHeaderName;
    this._bearerPrefix = bearerPrefix.trim().concat(" ");
    this._excludedUrls = this.loadExcludedUrls(bearerExcludedUrls);
    this._silentRefresh = initOptions ? initOptions.flow === "implicit" : false;
  }

  init(options: KeycloakOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.initServiceValues(options);
      const { config, initOptions } = options;

      this._instance = Keycloak(environment.keycloakConfig);
      this.bindsKeycloakEvents();
      this._instance
                .init(initOptions)
                .success(async authenticated => {
                  if (authenticated && this._loadUserProfileAtStartUp) {
                    await this.loadUserProfile();
                  }
                  resolve(authenticated);
                })
                .error(kcError => {
                  let msg = "An error happened during Keycloak initialization.";
                  if (kcError) {
                    let { error, error_description } = kcError;
                    msg = msg.concat(
                            `\nAdapter error details:\nError: ${error}\nDescription: ${error_description}`,
                        );
                  }
                  reject(msg);
                });
    });
  }

  login(options: Keycloak.KeycloakLoginOptions = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      this._instance
                .login(options)
                .success(async () => {
                  if (this._loadUserProfileAtStartUp) {
                    await this.loadUserProfile();
                  }
                  resolve();
                })
                .error(() => reject(`An error happened during the login.`));
    });
  }

  logout(redirectUri?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const options: any = {
        redirectUri,
      };

      this._instance
                .logout(options)
                .success(() => {
                  this._userProfile = undefined;
                  resolve();
                })
                .error(() => reject("An error happened during logout."));
    });
  }

  isUserInRole(role: string, resource?: string): boolean {
    let hasRole: boolean;
    hasRole = this._instance.hasResourceRole(role, resource);
    if (!hasRole) {
      hasRole = this._instance.hasRealmRole(role);
    }
    return hasRole;
  }

  getUserRoles(allRoles = true): string[] {
    let roles: string[] = [];
    if (this._instance.resourceAccess) {
      for (const key in this._instance.resourceAccess) {
        if (this._instance.resourceAccess.hasOwnProperty(key)) {
          const resourceAccess: any = this._instance.resourceAccess[key];
          const clientRoles = resourceAccess["roles"] || [];
          roles = roles.concat(clientRoles);
        }
      }
    }
    if (allRoles && this._instance.realmAccess) {
      let realmRoles = this._instance.realmAccess["roles"] || [];
      roles.push(...realmRoles);
    }
    return roles;
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      if (!this._instance.authenticated) {
        return false;
      }
      await this.updateToken(20);
      return true;
    } catch (error) {
      return false;
    }
  }

  isTokenExpired(minValidity = 0): boolean {
    return this._instance.isTokenExpired(minValidity);
  }

  updateToken(minValidity = 5): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
            // TODO silent refresh pending
      if (this._silentRefresh) {
        if (this.isTokenExpired()) {
          reject("Failed to refresh the token, or the session is expired");
        } else {
          resolve(true);
        }
        return;
      }

      if (!this._instance) {
        reject("Keycloak Angular library is not initialized.");
        return;
      }

      this._instance
                .updateToken(minValidity)
                .success(refreshed => {
                  resolve(refreshed);
                })
                .error(() => reject("Failed to refresh the token, or the session is expired"));
    });
  }

  loadUserProfile(forceReload = false): Promise<Keycloak.KeycloakProfile> {
    return new Promise(async (resolve, reject) => {
      if (this._userProfile && !forceReload) {
        resolve(this._userProfile);
        return;
      }

      if (!this._instance.authenticated) {
        reject("The user profile was not loaded as the user is not logged in.");
        return;
      }

      this._instance
                .loadUserProfile()
                .success(result => {
                  this._userProfile = result as Keycloak.KeycloakProfile;
                  resolve(this._userProfile);
                })
                .error(() => reject("The user profile could not be loaded."));
    });
  }

  getToken(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.updateToken(10);
        resolve(this._instance.token);
      } catch (error) {
        this.login();
      }
    });
  }

  getUserFullName(): string {
    if (!this._userProfile) {
      throw new Error("User not logged in or user profile was not loaded.");
    }

    return `${this._userProfile.firstName} ${this._userProfile.lastName}`;
  }

  getUsername(): string {
    if (!this._userProfile) {
      throw new Error("User not logged in or user profile was not loaded.");
    }

    return this._userProfile.username!;
  }

  clearToken(): void {
    this._instance.clearToken();
  }

  getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this._instance;
  }

  get excludedUrls(): ExcludedUrlRegex[] {
    return this._excludedUrls;
  }

  get enableBearerInterceptor(): boolean {
    return this._enableBearerInterceptor;
  }

  get keycloakEvents$(): Subject<KeycloakEvent> {
    return this._keycloakEvents$;
  }
}
