import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Moment } from "moment";
import * as moment from "moment";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-nav-bar-date-time",
  templateUrl: "./nav-bar-date-time.component.html",
  styleUrls: ["./nav-bar-date-time.component.scss"],
})
export class NavBarDateTimeComponent implements OnInit {
  public dateTime: Moment;

  private onDestroy: Subject<void> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    this.setupTimeUpdateOnInterval();
  }

  public refresh() {
    this.dateTime = moment();
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private setupTimeUpdateOnInterval() {
    this.refresh();
    const now = moment();
    const msUntilEndOfMinute = now.endOf("minute").diff(now);
    timer(msUntilEndOfMinute)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.setupTimeUpdateOnInterval());
  }
}
