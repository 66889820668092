import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformJson } from './json/platform.json';
import { Platform } from './models/platform';
import { PlatformsModelsFactory } from './models/platforms-models.factory';


@Injectable({ providedIn: 'root' })
export class PlatformsService {

  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private currentAirportService: CurrentAirportService,
    private platformsModelsFactory: PlatformsModelsFactory,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public load(): Observable<Platform[]> {
    return this
      .httpClient
      .get<PlatformJson[]>(`${this.apiUrl}/v1/catalog/platforms/by-airport/${this.currentAirportService.currentIATA}`)
      .pipe(
        map(jsonItems => jsonItems.map(json => this.platformsModelsFactory.createPlatform(json))),
      );
  }

}
