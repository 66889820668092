import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import { DateTimeFormatter } from '@shared/services/utility/date-time-formatter.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VehicleStatusesEnum } from './enum/vehicle-statuses.enum';
import { RemainingFuelJson } from './interfaces/remaining-fuel.json-interface';
import { VehicleCoordinatesJson } from './interfaces/vehicle-coordinates.json-interface';
import { VehicleJson } from './interfaces/vehicle.json-interface';
import { VehiclesAvailabilityJson } from './interfaces/vehicles-availability.json-interface';
import { VehicleAvailability } from './models/vehicle-availability';
import { VehicleCoordinates } from './models/vehicle-coordinates';
import { VehicleRemainingFuel } from './models/vehicle-remaining-fuel';
import { VehiclesModelsFactory } from './models/vehicles-models.factory';


@Injectable({ providedIn: 'root' })
export class VehiclesService {

  private apiUrl: string;

  private scoutApiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private vehiclesModelsFactory: VehiclesModelsFactory,
    private dateTimeFormatter: DateTimeFormatter,
    private currentAirportService: CurrentAirportService,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
    this.scoutApiUrl = configService.scoutEndpointUrl;
  }

  public loadVehicles() {
    return this
      .httpClient
      .get<VehicleJson[]>(`${ this.apiUrl }/v1/vehicles/by-airport/${ this.currentAirportService.currentIATA }`)
      .pipe(
        map(vehicles => vehicles.map(vehicle => this.vehiclesModelsFactory.createVehicle(vehicle))),
      );
  }

  public loadVehicleStatuses() {
    return this
      .httpClient
      .get<any[]>(`${ this.apiUrl }/v1/vehicles/vehicle-flight-states/${ this.currentAirportService.currentIATA }`)
      .pipe(
        map(rawJson => {
          const result: { [id: string]: VehicleStatusesEnum } = {};

          rawJson.forEach(item => result[item.vehicle.id] = item.status);

          return result;
        }),
        map(statuses => {
          const result = new Map<string, VehicleStatusesEnum>();
          Object
            .keys(statuses)
            .forEach(id => result.set(id, statuses[id]));

          return result;
        }),
      );

  }

  public loadVehiclesRemainingFuel() {
    return this
      .httpClient
      .get<RemainingFuelJson[]>(`${ this.apiUrl }/v1/vehicles/airport/${ this.currentAirportService.currentIATA }/remain-fuel-volumes`)
      .pipe(
        map(items => {
          const result = new Map<string, VehicleRemainingFuel>();
          items
            .forEach(json => result.set(json.vehicle_id, this.vehiclesModelsFactory.createVehicleRemainingFuel(json)));

          return result;
        }),
      );
  }

  public loadVehicleDrivers() {
    return this
      .httpClient
      .get<{ [id: string]: string }>(`${ this.apiUrl }/v1/vehicles/airport/${ this.currentAirportService.currentIATA }/drivers`)
      .pipe(
        map(drivers => {
          const result = new Map<string, string>();
          Object
            .keys(drivers)
            .forEach(vehicleId => result.set(vehicleId, drivers[vehicleId]));

          return result;
        }),
        catchError(() => of(new Map<string, string>())),
      );
  }

  public loadVehiclesCoordinates(): Observable<VehicleCoordinates[]> {
    return this
      .httpClient
      .get<VehicleCoordinatesJson[]>(`${ this.scoutApiUrl }/vehicles/airport/${ this.currentAirportService.currentIATA }/coordinates`)
      .pipe(
        map(json => json.map(jsonCoordinates => this.vehiclesModelsFactory.createVehicleCoordinates(jsonCoordinates))),
        catchError(() => of([])),
      );
  }

  public loadVehiclesAvailability(): Observable<VehicleAvailability[]> {
    return this
      .httpClient
      .get<VehiclesAvailabilityJson>(`${ this.apiUrl }/v1/vehicles/vehicles-availability/${ this.currentAirportService.currentIATA }`)
      .pipe(
        map(json => {
          const vehiclesAvailability: VehicleAvailability[] = [];

          Object
            .keys(json)
            .forEach(vehicleId => vehiclesAvailability
              .push(new VehicleAvailability(vehicleId, json[vehicleId])),
            );

          return vehiclesAvailability;
        }),
        catchError(() => of([])),
      );
  }

}
