import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Airport } from '@shared/models/airport.model';
import { AvailableAirportsService } from '@shared/services/business/available-airports.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AvailableAirportsGuard implements CanActivate, CanActivateChild {
  private readonly redirectUrlTree: UrlTree;

  constructor(private availableAirportsService: AvailableAirportsService, router: Router) {
    this.redirectUrlTree = router.parseUrl('/access-denied');
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.availableAirportsService.availableAirports$.pipe(
      take(1),
      map(airports => airports.length > 0 ? true : this.redirectUrlTree),
    );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(next, state);
  }
}
