import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../app.shared";
import { NavBarAirportComponent } from "./components/nav-bar-airport/nav-bar-airport.component";
import { NavBarDateTimeComponent } from "./components/nav-bar-date-time/nav-bar-date-time.component";
import { NavBarPagesComponent } from "./components/nav-bar-pages/nav-bar-pages.component";
import { NavBarUserComponent } from "./components/nav-bar-user/nav-bar-user.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    NavBarComponent,
    NavBarAirportComponent,
    NavBarPagesComponent,
    NavBarDateTimeComponent,
    NavBarUserComponent,
  ],
  exports: [
    NavBarComponent,
  ],
})
export class NavBarModule { }
