import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@shared/services/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AirportJson } from './json/airport.json';
import { Airport } from './models/airport';
import { AirportsModelsFactory } from './models/airports-models.factory';


@Injectable({ providedIn: 'root' })
export class AirportsService {

  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private airportsModelsFactory: AirportsModelsFactory,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public load(): Observable<Airport[]> {
    return this
      .httpClient
      .get<AirportJson[]>(`${ this.apiUrl }/v1/catalog/airports/`)
      .pipe(
        map(jsonItems => jsonItems.map(json => this.airportsModelsFactory.createAirport(json))),
      );
  }

}
