import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDatepicker, NgbDatepickerI18n, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { DateTimeFormatter } from "@shared/services/utility/date-time-formatter.service";
import { NgbDateService } from "@shared/services/utility/ngb-date.service";
import * as moment from "moment";

import { getLastDateOfMonth } from "../../../../utils/get-last-date-of-month";
import { CustomRussianDatepickerI18n, DatepickerI18n } from "../../services/custom-russioan-datepicker-i18n.service";
import { RangeDateBoxChangeEvent, RangeDateBoxInitializedEvent } from "./range-date-box.model";


@Component({
  selector: 'app-range-date-box',
  templateUrl: './range-date-box.component.html',
  styleUrls: ['./range-date-box.component.scss'],
  providers: [DatepickerI18n, { provide: NgbDatepickerI18n, useClass: CustomRussianDatepickerI18n }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeDateBoxComponent implements AfterViewInit, OnInit {

  @Input() monthNumber = 1;

  @Output() onInit = new EventEmitter<RangeDateBoxInitializedEvent>();
  @Output() onAfterViewInit = new EventEmitter<RangeDateBoxInitializedEvent>();
  @Output() onChange = new EventEmitter<RangeDateBoxChangeEvent>();

  @ViewChild(NgbDropdown, { static: false }) dropdown: NgbDropdown;
  @ViewChild(NgbDatepicker, { static: false }) datepicker: NgbDatepicker;

  hoveredDate: NgbDate;
  textValue: string;

  readonly defaultRangeDateFormat = {
    from: "DD.MM YYYY",
    delimiter: " - ",
    to: "DD.MM YYYY",
  };

  rangeDateFormat = this.defaultRangeDateFormat;

  private _fromDate: NgbDate;

  @Input() set fromDate(value: NgbDate) {
    this._fromDate = value;
    this.fireOnChange();
    this.setTextValue();
  }

  get fromDate() {
    return this._fromDate;
  }

  private _toDate: NgbDate;

  @Input() set toDate(value: NgbDate) {
    this._toDate = value;
    this.fireOnChange();
    this.setTextValue();
  }

  get toDate() {
    return this._toDate;
  }

  @Input() minDate: NgbDate;

  @Input() maxDate: NgbDate;

  @Input() needDefaultValue = true;

  @Input()
  public singleDateAllow = false;

  constructor(
    private datePipe: DatePipe,
    private ngbDateService: NgbDateService,
    private dateFormatter: DateTimeFormatter,
    private cd: ChangeDetectorRef,
  ) {
  }

  setDateRange(from: NgbDate, to: NgbDate) {
    if (!(from && to)) {
      console.warn('Need both arguments in setDateRange()');
      return;
    }

    this._fromDate = from;
    this._toDate = to;

    this.fireOnChange();
    this.setTextValue();
    this.updateView();
  }

  ngOnInit() {
    this.onInit.emit({ instance: this });
  }

  ngAfterViewInit() {
    this.onAfterViewInit.emit({ instance: this });
    if (!(this.fromDate || this.toDate) && this.needDefaultValue) {
      this.selectCurrentMonth();
    }
  }

  open() {
    this.dropdown.open();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      if (!this.singleDateAllow && this.fromDate.equals(date)) {
        this.fromDate = null;
      } else {
        if (date.after(this.fromDate)) {
          this.toDate = date;
        } else {
          this.toDate = this.fromDate;
          this.fromDate = date;
        }
        this.dropdown.close();
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date);
  }

  public setTextValue() {
    if (!this.fromDate || !this.toDate) return;

    const from = this.ngbDateService.convertNgbDateToMoment(this.fromDate);
    const to = this.ngbDateService.convertNgbDateToMoment(this.toDate);

    this.textValue =
      this.dateFormatter.format(from, this.rangeDateFormat.from) +
      this.rangeDateFormat.delimiter +
      this.dateFormatter.format(to, this.rangeDateFormat.to);
  }

  selectCurrentMonth() {
    const today = new Date();
    const [year, month] = [today.getFullYear(), today.getMonth() + 1];

    this.fromDate = new NgbDate(year, month, 1);
    this.toDate = new NgbDate(year, month, getLastDateOfMonth(year, month));

    this.setTextValue();
    this.updateView();
  }

  private fireOnChange() {
    this.onChange.emit({
      value: {
        from: this.fromDate,
        to: this.toDate,
      },
      instance: this,
    });
  }

  updateView() {
    this.cd.detectChanges();
  }

  isToday(date: NgbDate) {
    return this.ngbDateService.isToday(date);
  }

  setRangeFromInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const dates = target.value.split(" - ").map(d => moment(d, "DD.MM YYYY"));
    if (dates.every(d => d.isValid()) && dates.length === 2) {
      const [from, to] = dates.map(d => this.ngbDateService.convertDateToNgbDate(d.toDate()));
      this.setDateRange(from, to);
    } else {
      console.warn("dates not valid");
    }
  }
}
