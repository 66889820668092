import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormatter } from '@shared/services/utility/date-time-formatter.service';
import * as moment from 'moment-timezone';


@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {

  constructor(private formatter: DateTimeFormatter) {
  }

  public transform(startDate: moment.MomentInput | null, finishDate: moment.MomentInput | null = null, outputFormat = 'HH:mm'): string {
    return this.formatter.getTimeDifference(startDate, finishDate, outputFormat);
  }
}
