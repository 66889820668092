import { Injectable } from '@angular/core';
import { returnVoid } from '@shared/functions/return-void.function';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Platform } from './models/platform';
import { PlatformsService } from './platforms.service';


@Injectable({ providedIn: 'root' })
export class PlatformsStore {

  private platformsSubject = new BehaviorSubject<Platform[]>([]);

  private indexById = new Map<number, Platform>();

  constructor(
    private platformsService: PlatformsService,
  ) {
  }

  public init(): Observable<void> {
    return this
      .platformsService
      .load()
      .pipe(
        tap(platforms => this.set(platforms)),
        map(returnVoid),
      );
  }

  public get(): Platform[] {
    return this.platformsSubject.getValue();
  }

  public findById(id: number): Platform | null {
    return this.indexById.get(id) || null;
  }

  public changes(): Observable<Platform[]> {
    return this.platformsSubject.asObservable();
  }

  public addIfNotExists(platforms: Platform[]): void {
    const notAddedPlatforms = platforms.filter(platform => !this.indexById.has(platform.id));
    if (notAddedPlatforms.length === 0) {
      return;
    }

    const newPlatforms = [...this.platformsSubject.getValue()];
    notAddedPlatforms.forEach(platform => {
      this.indexById.set(platform.id, platform);
      newPlatforms.push(platform);
    });

    this.platformsSubject.next(platforms);
  }

  public set(platforms: Platform[]): void {
    this.indexById = new Map<number, Platform>(platforms.map(platform => [platform.id, platform]));
    this.platformsSubject.next(platforms);
  }

}

