import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {first} from "rxjs/operators";
import {ToastService} from "@shared/services/toast.service";
import {environment} from "@environments/environment";

export class Config {
  backendURI: string;
  reportEndpointURI: string;
  scoutEndpointURI: string;
  apiUrls: { [key: string]: string };
  configUrl: string;
  authUrl: string;
  version: number;
  offlineAlertAttempts: number;
  blockchainPingFrequency: number;
  flightPollingInterval: number;
  taskPollingInterval: number;
  basicAuthConfig: string;
  visualChangeLiveTime: number;
    // tableSettings: { status: FlightStatus, colors: {} } // нигде не используется, в ответе на запрос не приходит поле

}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  config = new Config();

  public get apiUrl() {
    return this.config.backendURI;
  }

  public get reportEndpointUrl() {
    return this.config.reportEndpointURI;
  }

  public get scoutEndpointUrl() {
    return this.config.scoutEndpointURI;
  }

  public get apiUrls() {
    return this.config.apiUrls;
  }

  public get settings() {
    return this.config;
  }

  constructor(private http: HttpClient, private toast$: ToastService) {
    this.config.version = 1;
  }

  public load() {
    return new Promise((resolve, reject) => {
            //получаем данные из енв, записываем
      this.config = {...this.config, ...environment};

      let config = JSON.parse(localStorage.getItem("config"));
      if (config) {
        try {
          this.config = {...this.config, ...config};
          resolve(true);
        } catch (e) {
                    //  this.toast$.showError('Cant update config - improper local settings')
        }
      } else {
        localStorage.setItem("config", JSON.stringify(this.config));
        resolve(true);
      }
    });
  }
}
