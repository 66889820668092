import { Injectable } from '@angular/core';
import { AircraftJson } from '../json/aircraft.json';
import { Aircraft } from './aircraft';


@Injectable({ providedIn: 'root' })
export class AircraftsModelsFactory {

  public static readonly NULL_VALUES = new Set<string>(['', '-']);

  public createAircraft(id: number | null, type: string | null, number: string | null): Aircraft {
    return new Aircraft(
      id,
      this.valueOrNull(type),
      this.valueOrNull(number),
    );
  }

  public createAircraftFromJson(json: AircraftJson): Aircraft {
    return this.createAircraft(json.id, json.type, json.number);
  }

  private valueOrNull(value: string | null): string | null {
    return AircraftsModelsFactory.NULL_VALUES.has(value) ? null : value;
  }

}
