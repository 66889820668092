import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { Tab, TabsEnum, tabsList } from './tabs.list';


@Component({
  selector: "app-nav-bar-pages",
  templateUrl: "./nav-bar-pages.component.html",
  styleUrls: ["./nav-bar-pages.component.scss"],
})
export class NavBarPagesComponent implements OnChanges {
  @Input()
  public route: string;

  @Output()
  public onChange = new EventEmitter<string>();

  public tabs: Tab[] = [];

  constructor(
    private currentAirportService: CurrentAirportService,
    private router: Router,
  ) {
    this.initTabs();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.route) {
      this.checkAllowedUrl();
    }
  }

  public change($event: NgbTabChangeEvent) {
    this.onChange.emit($event.nextId);
  }

  private initTabs(): void {
    let tabs = tabsList;

    if (this.currentAirportService.currentIATA !== 'SVO') {
      tabs = tabs.filter(tab => tab.id !== TabsEnum.AIRPORT_MAP);
    }

    this.tabs = tabs;
  }

  private checkAllowedUrl(): void {
    const foundRoute = this.tabs.some(tab => this.route.includes(tab.route));

    if (!foundRoute) {
      this.router.navigate(['/'])
    }
  }

}

