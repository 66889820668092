import { Component, OnInit, ComponentFactoryResolver, ViewChild, Input, Renderer2, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService, EventMetadata } from '@shared/services/modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ModalInstance extends EventMetadata {
  instance: NgbModalRef,
}

interface ModalDict {
  [key: string]: ModalInstance;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {

  constructor(
    private ngbModalService: NgbModal,
    private modalService: ModalService,
    private renderer: Renderer2,
  ) {}

  private destroy$ = new Subject<void>();
  private uniquePropertiesDict = {};
  private modalStore: ModalDict = {};

  @Output() beingDestroyed = new EventEmitter<boolean>();

  ngOnInit() {
    this.modalService.requestForModalWithComponent
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: EventMetadata) => this.createModalWithComponent(data));
    
    this.modalService.requestCloseModalByName
        .pipe(takeUntil(this.destroy$))
        .subscribe((modalName: string) => this.closeModalFromComponent(modalName));
  }

  private createModalWithComponent(data: EventMetadata) {
    if (data.options.prop && this.uniquePropertiesDict[data.options.prop]) {
      this.updateDataInsideModal(data);
      return;
    }

    this.uniquePropertiesDict = {...this.uniquePropertiesDict, [data.options.prop]: true};
    const modalRef = this.createModalInstance(data);
    this.addAdditionalClass(data);
    modalRef.result.finally(() => this.removeModalMetadata(data));

    if (data.options.modalName) {
      this.modalStore[data.options.modalName] = { ...data, instance: modalRef };
    }
  }

  private updateDataInsideModal(data: EventMetadata) {
    const instance = this.modalStore[data.options.modalName].instance;
    instance.componentInstance.options = data.inputData;
  }

  private createModalInstance(data: EventMetadata) {
    const modalRef = this.ngbModalService.open(data.component, data.options);
    modalRef.componentInstance.options = data.inputData;
    return modalRef;
  }

  private removeModalMetadata(data: EventMetadata) {
    delete this.uniquePropertiesDict[data.options.prop];
    data.options.callback();
    this.removeAdditionalClass(data);
  }

  private addAdditionalClass(data: EventMetadata) {
    if (data.options && data.options.bodyClass) {
      this.renderer.addClass(document.body, data.options.bodyClass);
    }
  }

  private removeAdditionalClass(data: EventMetadata) {
    if (data.options && data.options.bodyClass) {
      this.renderer.removeClass(document.body, data.options.bodyClass);
    }
  }

  private closeModalFromComponent(modalName: string) {
    if (this.modalStore[modalName]) {
      this.modalStore[modalName].instance.close();
    } else {
      console.warn(`Modal instance with name: ${modalName} not exist!`);
    }
  }

  ngOnDestroy() {
    this.beingDestroyed.emit();
    this.destroy$.next();
    this.destroy$.complete();
  }

}
