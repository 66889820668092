import { Injectable } from '@angular/core';
import { DateTimeRange } from '@shared/models/date-time-range';
import { DateTimeFormatter } from '@shared/services/utility/date-time-formatter.service';
import { AircraftsModelsFactory } from '../../../aircrafts/common/models/aircrafts-models.factory';
import { AirlinesStore } from '../../../airlines/common/airlines.store';
import { AntiIcingFluidsEnum } from '../../../anti-icing-fluids/common/enums/anti-icing-fluids.enum';
import { PlatformsModelsFactory } from '../../../platforms/common/models/platforms-models.factory';
import { FuelDataSourcesEnum } from '../enums/fuel-data-sources.enum';
import { FlightJson } from '../json/flight.json';
import { FuelJson } from '../json/fuel.json';
import { Airport } from './airport';
import { Arrival } from './arrival';
import { Departure } from './departure';
import { Flight } from './flight';
import { FlightFuel } from './flight-fuel';
import { Fuel } from './fuel';

//todo: rename to FlightsModelsFactory
@Injectable()
export class FlightsFactory {

  constructor(
    private dateTimeFormatter: DateTimeFormatter,
    private platformsModelsFactory: PlatformsModelsFactory,
    private airlinesStore: AirlinesStore,
    private aircraftsModelsFactory: AircraftsModelsFactory,
  ) {
  }

  public createFuel(json: FuelJson, dataSource: FuelDataSourcesEnum | null = null) {
    const quantity = isNaN(json.quantity) ? null : json.quantity;

    return new Fuel(json.unit, quantity, dataSource);
  }

  public createFlight(json: FlightJson) {
    let aircraft = json.aircraft ? this.aircraftsModelsFactory.createAircraftFromJson(json.aircraft) : null;
    if (aircraft && !aircraft.type && !aircraft.number) {
      aircraft = null;
    }

    return new Flight(
      json.id,
      json.source,
      json.status,
      json.schedule_flight_status,
      json.number,
      this.airlinesStore.findByName(json.airline_name),
      aircraft,
      this.createFlightFuelFromFlightJson(json),
      this.createDateTimeRange(json.tgo_start_plan_date_time, json.tgo_finish_plan_date_time),
      this.createDateTimeRange(json.tgo_start_fact_date_time, json.tgo_finish_fact_date_time),
      json.tasks_count,
      json.destination,
      json.anti_icing_fluid ? json.anti_icing_fluid.id : AntiIcingFluidsEnum.NO,
      this.createDepartureFromFlightJson(json),
      this.createArrivalFromFlightJson(json),
      json.reference || null,
      json.order_id,
      json.accepter_id,
      json.platform && json.platform.id !== 0 ? this.platformsModelsFactory.createPlatform(json.platform) : null,
      json.service_type,
      json.kvys,
      json.terminal_departure,
      json.international_domestic_sign,
      json.international_domestic2_sign,
      json.sr_dat,
      json.p_npos,
      json.in_registry,
    );
  }

  private createDateTimeRange(start: string | null, finish: string | null) {
    if (!start && !finish) {
      return null;
    }

    return new DateTimeRange(this.normalizeDateTime(start), this.normalizeDateTime(finish));
  }

  private createFlightFuelFromFlightJson(json: FlightJson) {
    let planFuel: Fuel | null = null;
    const dispatcherFuel = json.fuel && json.fuel.quantity !== null ? this.createFuel(json.fuel, FuelDataSourcesEnum.DISPATCHER) : null;
    const forecastFuel = json.fuel_forecast && json.fuel_forecast.quantity !== null ? this.createFuel(
      json.fuel_forecast,
      FuelDataSourcesEnum.FORECAST,
    ) : null;
    const accurateForecastFuel = json.fuel_accurate_forecast && json.fuel_accurate_forecast.quantity !== null
      ? this.createFuel(json.fuel_accurate_forecast, FuelDataSourcesEnum.ACCURATE_FORECAST) : null;

    if (dispatcherFuel) {
      planFuel = this.createFuel(dispatcherFuel, FuelDataSourcesEnum.DISPATCHER);
    } else if (accurateForecastFuel) {
      planFuel = this.createFuel(accurateForecastFuel, FuelDataSourcesEnum.ACCURATE_FORECAST);
    } else if (forecastFuel) {
      planFuel = this.createFuel(forecastFuel, FuelDataSourcesEnum.FORECAST);
    }

    return new FlightFuel(
      dispatcherFuel,
      forecastFuel,
      accurateForecastFuel,
      planFuel,
      json.fuel_fact ? this.createFuel(json.fuel_fact) : null,
    );
  }

  private createDepartureFromFlightJson(json: FlightJson) {
    return new Departure(
      new Airport(json.departure_airport_iata_code, null),
      json.departure_code || null,
      this.normalizeDateTime(json.departure_date_time),
      this.normalizeDateTime(json.departure_fact_date_time),
    );
  }

  private createArrivalFromFlightJson(json: FlightJson) {
    if (
      !json.arrival_airport_iata_code && !json.arrival_date_time &&
      !json.arrival_departure_sign && !json.arrival_departure_transit_sign
    ) {
      return null;
    }

    return new Arrival(
      json.arrival_airport_iata_code ? new Airport(json.arrival_airport_iata_code, json.arrival_airport_name) : null,
      this.normalizeDateTime(json.arrival_date_time),
      json.arrival_departure_sign,
      json.arrival_departure_transit_sign,
    );
  }

  private normalizeDateTime(dateTime: string | null) {
    return dateTime ? this.dateTimeFormatter.toFullIsoWithMilliseconds(dateTime) : null;
  }

}
