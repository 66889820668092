import { AntiIcingFluidsEnum } from '../../../anti-icing-fluids/common/enums/anti-icing-fluids.enum';
import { VehicleTaskStatesEnum } from '../enum/vehicle-task-states.enum';
import { VehicleTaskStatusesEnum } from '../enum/vehicle-task-statuses.enum';
import { VehicleTasksTypesEnum } from '../enum/vehicle-tasks-types.enum';
import { TaskHistoryItem } from './task-history-item.interface';


export class TaskFuelingHistoryItem implements TaskHistoryItem {

  public readonly type = VehicleTasksTypesEnum.FUELING;

  constructor(
    public id: string,
    public refuelingId: string,
    public platformId: number,
    public reasonId: number,
    public flightNumber: string,
    public dateTime: string,
    public aircraftType: string,
    public aircraftNumber: string,
    public taskNumber: string,
    public taskStatus: VehicleTaskStatusesEnum,
    public taskState: VehicleTaskStatesEnum | null,
    public userLogin: string,
    public antiIcingFluidsEnum: AntiIcingFluidsEnum,
    public planMass: number,
    public planVolume: number,
    public factMass: number,
    public factVolume: number,
  ) {
  }
}
