import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() type = "button";
  @Input() kind = "primary";
  @Input() customClass: string;
  @Input() icon: string;
  @Input() text: string;
  @Input() disabled = false;
  @Input() size: "lg" | "sm";
  // TODO add support for primitive string
  @Input() tooltip: () => string;

  @Output() onClick = new EventEmitter<MouseEvent>();
}
