import { Injectable } from '@angular/core';
import { UserContext } from '@shared/models/system/user-context.model';
import { KeycloakService } from '@shared/services/keycloak.service';
import { BehaviorSubject, Observable } from 'rxjs';

const defaultContext: UserContext = {
  roles: [],
};

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  public context$: Observable<UserContext>;
  private contextSubj = new BehaviorSubject<UserContext>(defaultContext);

  constructor(private keycloakService: KeycloakService) {
    this.context$ = this.contextSubj.asObservable();
  }

  public refresh() {
    this.keycloakService.isLoggedIn().then(loggedIn => {
      console.log('loggedIn', loggedIn);
      if (loggedIn) {
        this.createUserContext().then(context => this.contextSubj.next(context));
      } else {
        this.contextSubj.next(defaultContext);
      }
    });
  }

  private async createUserContext(): Promise<UserContext> {
    const { id, username, firstName, lastName, email } = await this.keycloakService.loadUserProfile();
    return {
      roles: this.keycloakService.getUserRoles(true),
      profile: { id, username, firstName, lastName, email },
    };
  }

  private createEmptyUserContext(): UserContext {
    return { ...defaultContext };
  }
}
