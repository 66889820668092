import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ControlTicket } from '@shared/models/control-ticket';
import { Aircraft, Flight, TableFlightsData } from '@shared/models/flight.model';
import { Task, TaskHistory, LastTaskStatuses, LastStatuses, CombinedTasksAndStatuses } from '@shared/models/task.model';
import { VehicleRemainFuel } from '@shared/models/vehicleRemainFuel.model';
import { Vehicle } from '@shared/models/vehicles.model';
import { VehicleStateHistory } from '@shared/models/vehicleStateHistory.model';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import { AirportInfoService } from '@shared/services/dictionary/airport-info.service';
import { DateTimeFormatter } from '@shared/services/utility/date-time-formatter.service';
import * as moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { RsqlFilter, RsqlOperator } from '../../applications/models/rsql-filter';
import { TZAStates } from '../components/tza-card/tza-card.component';
import { VehicleTaskStatusesEnum } from '../../widgets/vehicle-tasks/common/enum/vehicle-task-statuses.enum';
import { VehicleTaskReasonsOfRejectEnum } from '../../widgets/vehicle-tasks/common/enum/vehicle-task-reasons-of-reject.enum';

@Injectable({
  providedIn: "root",
})
export class FlightsService {
  protected _dataFlight$: BehaviorSubject<TableFlightsData> = new BehaviorSubject({
    _yesterday: [],
    today: [],
    tomorrow: [],
  });

  public dataFlight$: Observable<TableFlightsData> = this._dataFlight$.asObservable();

  private _changes: any = {};

  protected _oldDataFlight$: BehaviorSubject<Map<string, Flight>> = new BehaviorSubject(new Map());
  public oldDataFlight$: Observable<Map<string, Flight>> = this._oldDataFlight$.asObservable();

  private skeletonLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  skeletonLoadingState = this.skeletonLoadingSubject.asObservable();

  protected readonly EMPTY: string = "—";

  private apiUrl: string;

  constructor(
    protected injector: Injector,
    private airportService: AirportInfoService,
    private currentAirportService: CurrentAirportService,
    private dateTimeFormatter: DateTimeFormatter,
    private http: HttpClient,
    private config$: ConfigService,
  ) {
    this.http = injector.get(HttpClient);
    this.apiUrl = config$.apiUrl;
  }

  public loadFlights(): void {
    this.skeletonLoading(true);

    this.getFlightsRSQL(this.createRsqlQuery())
      .pipe(first())
      .subscribe((data: Flight[]) => {
        let newData = this._sortDayArray(data);
        this._dataFlight$.next(newData);
        this.skeletonLoading(false);
      }, (error: any) => {
        this.skeletonLoading(false);
      },
      );
  }

  public skeletonLoading(state: boolean) {
    this.skeletonLoadingSubject.next(state);
  }

  public loadTaskStates(): Observable<any> {
    const day = moment().subtract(1, 'day').endOf('day').toISOString();

    return this.getTasksRSQL("localCreateDateTime>" + day).pipe(
      map((data: Array<Task>) => {
        let groupedArray = data.reduce((startingArray, element) => {
          if (element.flight) {
            if (!startingArray[element.flight.id]) {
              startingArray[element.flight.id] = element.state;
            } else {
              if (startingArray[element.flight.id] < element.state) {
                startingArray[element.flight.id] = element.state;
              }
            }
          }
          return startingArray;
        }, {});
        return groupedArray;
      }),
    );
  }

  public getTasksRSQLByVehicleId(vehicleId: string, date: string) {
    let rsql = `vehicleId==${vehicleId}%3BplanStartDateTime>${date}%3Bstatus!=${VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_SYSTEM}`;
    return this.getTasksRSQL(rsql).pipe(map(
      (data: Task[]) => {
        data = data.filter(item => {
          return new Date(date).getTime() < new Date(item.plan_start_date_time).getTime()
        })
        return data;
      },
    ));
  }

  pushChange(flightKey: any, field: any, value: any) {
    this._changes[flightKey + "/\\" + field] = value;
  }

  deleteChange(flightKey: any, field: any) {
    delete (this._changes[flightKey + "/\\" + field]);

  }

  refreshFlights() {
    this.getFlightsRSQL(this.createRsqlQuery())
      .pipe(first())
      .subscribe((data: Flight[]) => {
        let changesMap = new Map();
        let oldData = this._dataFlight$.getValue();
        let oldArray = oldData._yesterday;
        oldArray.push(...oldData.today);
        oldArray.push(...oldData.tomorrow);

        //******костыль чтобы не перетерались изменения ожидающие отправки (визуально), удалить как будет норм полниг
        for (let changesKey in this._changes) {
          let someArray = changesKey.split("/\\");
          let itemIndex = data.findIndex(item => item.id === someArray[0]);
          if (itemIndex != -1) {
            switch (someArray[1]) {
            case "aircraft_type":
              data[itemIndex][someArray[1]]['aircraft']['type'] = this._changes[changesKey];
              break;
            case "aircraft_number":
              data[itemIndex][someArray[1]]['aircraft']['number'] = this._changes[changesKey];
              break;
            default:
              data[itemIndex][someArray[1]] = this._changes[changesKey];
              break;
            }
          }
        }


        let newData = this._sortDayArray(data);
        data.forEach((flight, index) => {
          let oldItem = oldArray.find(item => item.id === flight.id);
          if (oldItem) {
            let changedFlight;
            for (let flightKey in flight) {
              if (flight[flightKey] && (flight[flightKey] != oldItem[flightKey])) {
                if (typeof flight[flightKey] == 'object') {
                  for (let flightElementKey in flight[flightKey]) {
                    if (flight[flightKey] && (!oldItem[flightKey] || flight[flightKey][flightElementKey] != oldItem[flightKey][flightElementKey])) {
                      if (!changedFlight) changedFlight = new Flight()
                      if (!changedFlight[flightKey]) changedFlight[flightKey] = {};
                      changedFlight[flightKey][flightElementKey] = oldItem[flightKey] ? oldItem[flightKey][flightElementKey] : null;
                    }
                  }
                } else {
                  if (!changedFlight) changedFlight = new Flight()
                  changedFlight[flightKey] = oldItem[flightKey];
                }
              }
            }
            if (changedFlight) {
              changesMap.set(flight.id, changedFlight);
            }
          }
        })
        this._dataFlight$.next(newData);
        if (changesMap.size) {
          this._oldDataFlight$.next(changesMap);
        } else {
          this._oldDataFlight$.next(new Map());
        }
      });
  }


  private _sortDayArray(data: Flight[]) {
    const today = moment().startOf('day');
    const todayDay = today.clone().date();
    const tomorrowDay = today.clone().add(1, 'day').date();
    const yesterdayDay = today.clone().add(-1, 'day').date();

    const groupedArray = data.reduce((startingArray, element) => {
      const day = moment(element.departure_date_time).date();
      if (!startingArray[day]) {
        startingArray[day] = [];
      }

      startingArray[day].push(element);
      return startingArray;
    }, {});

    let newData: TableFlightsData = {
      _yesterday: groupedArray[yesterdayDay] ? groupedArray[yesterdayDay] : [],
      today: groupedArray[todayDay] ? groupedArray[todayDay] : [],
      tomorrow: groupedArray[tomorrowDay] ? groupedArray[tomorrowDay] : [],
    };
    return newData;
  }


  public clearDataFlight(): void {
    this._dataFlight$.next({
      _yesterday: [],
      today: [],
      tomorrow: [],
    });
  }

  getFlightsRSQL(rsqlQuery: RsqlFilter): any {
    return this.http.get<Flight[]>(`${this.apiUrl}/v1/flights/${rsqlQuery.toUriEncodedString()}`).pipe(
      map(flights => {
        for (let flight of flights) {
          [
            'arrival_date_time',
            'departure_date_time',
            'tgo_finish_plan_date_time',
            'tgo_start_plan_date_time',
            'tgo_finish_fact_date_time',
            'tgo_start_fact_date_time',
          ].forEach(field => flight[field] = flight[field] && this.normalizeDate(flight[field]));

          if (!flight.aircraft) flight.aircraft = new Aircraft();
        }

        return flights;
      }),
    );
  }

  getTasksRSQL(rsqlQuery: string): any {
    return this.http.get(`${this.apiUrl}/v1/refuelings/${rsqlQuery}`).pipe(
      map((result: Task[]) => {
        result.map((task: Task) => {
          return this.normalizeTaskDates(task);
        });
        return result;
      }),
    );
  }

  getFlightTasks(flight_id: string): Observable<Task[]> {
    return this
      .http
      .get<Task[]>(`${this.apiUrl}/v1/refuelings/flight/${flight_id}/refuelings/status!=${VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_SYSTEM}`)
      .pipe(
        map(tasks => tasks.map(task => this.normalizeTaskDates(task))),
      );
  }

  setTask(task: Task): Observable<Task> {
    return this.http.post(`${this.apiUrl}/v1/refuelings/flight/${task.flight.id}/refueling`, task).pipe(
      map((result: Task) => {
        return this.normalizeTaskDates(result);
      }),
    );
  }

  updateTask(task: Task) {
    return this.http.put(`${this.apiUrl}/v1/refuelings/refueling/${task.id}`, task).pipe(
      map(result => {
        return result;
      }),
    );
  }

  patchFlight(flight: Flight) {
    return this.http.patch(`${this.apiUrl}/v1/flights/flight/${flight.id}`, flight).pipe(
      map((patchedFlight: Flight) => ({ ...patchedFlight, aircraft: patchedFlight.aircraft || new Aircraft() })),
    );
  }

  getTaskHistoryTza(taskId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/refuelings/refueling/${taskId}/history`).pipe(
      map((data: Array<TaskHistory>) => {
        let result = data.reduce((startingArray, element) => {
          let result: TZAStates = {
            stateId: element.task_state,
            inProgress: true,
            doneTime: element.date_time,
          };
          startingArray[element.task_state] = result;
          return startingArray;
        }, {});
        return result;
      }),
    );

  }

  private getTaskHistory(taskId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/refuelings/refueling/${taskId}/history`);
  }

  private getLastAndPreviousStates(history: TaskHistory[]) {
    return {
      previous: history[history.length - 2] ? history[history.length - 2].task_status : null,
      last: history[history.length - 1] ? history[history.length - 1].task_status : null,
    } as LastStatuses;
  }

  public getFlightTasksAndStatuses(flightId: string) {
    return this.getFlightTasks(flightId)
      .pipe(
        mergeMap((tasks: Task[]) => {
          let taskIds = tasks.map(task => this.getTaskHistory(task.id));
          if (!taskIds.length) {
            return of({
              tasks: [],
              lastStatuses: [],
            } as CombinedTasksAndStatuses);
          }
          return forkJoin(...taskIds).pipe(
            map(historyArray => {
              let tasksLastStatuses: LastTaskStatuses[] = [];
              tasks.forEach((task: Task, index: number) => {
                tasksLastStatuses.push({
                  taskId: task.id,
                  lastStatuses: this.getLastAndPreviousStates(historyArray[index]),
                })
              })
              return {
                tasks: tasks,
                lastStatuses: tasksLastStatuses,
              } as CombinedTasksAndStatuses;
            }),
          )
        }),
      );
  }

  getRecentControlTicketByVehicle(vehicle_id: string): Observable<ControlTicket> {
    return this.http.get(`${this.apiUrl}/v1/control-tickets/vehicle/${vehicle_id}/recent-control-ticket`).pipe(
      map(result => {
        return result as ControlTicket;
      }),
    );
  }

  getVehicle(vehicle_id: string): Observable<Vehicle> {
    return this.http.get(`${this.apiUrl}/v1/vehicles/vehicle/${vehicle_id}`).pipe(
      map(result => {
        return result as Vehicle;
      }),
    );
  }

  getVehicleStateHistory(vehicle_id: string): Observable<VehicleStateHistory[]> {
    return this.http.get(`${this.apiUrl}/v1/vehicles/vehicle/${vehicle_id}/history`).pipe(
      map(result => {
        return result as VehicleStateHistory[];
      }),
    );
  }

  getLastCreatedHistory(vehicle_id: string): Observable<VehicleRemainFuel> {
    return this.http.get(`${this.apiUrl}/v1/vehicles/vehicle/${vehicle_id}/last-created-history`).pipe(
      map(result => {
        return result as VehicleRemainFuel;
      }),
    );
  }

  normalizeDate(date: string | Date): string {
    return this.dateTimeFormatter.toFullIsoWithMilliseconds(moment(date).startOf('minute'));
  }

  private normalizeTaskDates(task: Task) {
    [
      'plan_finish_date_time',
      'plan_start_date_time',
      'fact_start_date_time',
      'fact_finish_date_time',
    ].forEach(field => task[field] = task[field] && this.normalizeDate(task[field]));
    return task;
  }

  private createRsqlQuery() {
    const start = moment().subtract(6, 'hours');
    const end = moment().add(12, 'hours');

    return RsqlFilter.EMPTY
      .set('departureDateTime', RsqlOperator.greater, start)
      .set('departureDateTime', RsqlOperator.lesser, end)
      .set('departureAirportIataCode', RsqlOperator.equals, this.currentAirportService.currentIATA);
  }

  public createFlight(flight: Flight) {
    return this.http.post<Flight>(`${this.apiUrl}/v1/flights/flight`, flight);
  }

  public getTaskReasonOfReject(task: Task) {
    return this.http.get<VehicleTaskReasonsOfRejectEnum>(`${ this.apiUrl }/v1/refuelings/refueling/${task.id}/reason`);
  }
}
