import { GanttIndex } from '@gpn/ngx-uikit';
import { VehicleTank } from './vehicle-tank';


export class Vehicle implements GanttIndex {
  constructor(
    public id: string,
    public name: string,
    public garageNumber: number,
    public hasPlatform: boolean,
    public hasBottomRefuelling: boolean,
    public hasDispensingGun: boolean,
    public fuelTank: VehicleTank,
    public antiIcingFluidTnk: VehicleTank | null,
  ) {
  }
}
