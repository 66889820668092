import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const i18nValues = {
  ru: {
    weekdays: [
      'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс',
    ],
    months: [
      'январь', 'февраль', 'март', 'апрель',
      'май', 'июнь', 'июль', 'август',
      'сентябрь', 'октябрь', 'ноябрь', 'декабрь',
    ],
  },
};

@Injectable()
export class DatepickerI18n {
  language = 'ru';
}

@Injectable()
export class CustomRussianDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: DatepickerI18n) {
    super();
  }

  getWeekdayShortName(weekday: number) {
    return i18nValues[this.i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number) {
    return i18nValues[this.i18n.language].months[month - 1];
  }

  getMonthFullName(month: number) {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct) {
    return `${date.day}.${date.month}.${date.year}`;
  }
}
