import * as moment from 'moment-timezone';


export class WorkShiftsDatesRange {
  constructor(
    public start: moment.Moment,
    public finish: moment.Moment,
  ) {
  }
}
