
export interface Tab {
  id: TabsEnum,
  route: string;
  title: string;
  icon: string;
}

export enum TabsEnum {
  ORDERS = 'ORDERS',
  FLIGHTS = 'FLIGHTS',
  TASKS = 'TASKS',
  GANTT = 'GANTT',
  AIRPORT_MAP = 'AIRPORT_MAP',
}

export const tabsList: Tab[] = [
  { id: TabsEnum.ORDERS, route: "/orders", title: "Заявки", icon: "icon-list-elips" },
  { id: TabsEnum.FLIGHTS, route: "/flights", title: "Рейсы", icon: "icon-plane" },
  // { id: TabsEnum.TASKS, route: "/tasks", title: "Задания", icon: "icon-doc" },
  { id: TabsEnum.GANTT, route: "/gantt", title: "Гантт", icon: "icon-gantt" },
  { id: TabsEnum.AIRPORT_MAP, route: "/airport-map", title: "Схема", icon: "icon-geo-point" },
];
