// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KeycloakConfig } from "keycloak-angular";

const keycloakConfig: KeycloakConfig = {
  url: "https://al.smartfuel.aero/auth",
  realm: "Aero-dispatcher",
  clientId: "aero-dispatcher-client",
};

const csvTemplatesConfig = {
  requiredFieldSign: "*",
  separatorSign: "~",
  applicationsTemplatePath: "assets/excel-templates/orders_template.xlsx",
  enableCatalogVerification: true,
  dateFormat: "DD.MM.YYYY",
};

export const environment = {
  production: false,
  hmr: false,
  backendURI: "https://al.smartfuel.aero/api",
  reportEndpointURI: "http://reports",
  scoutEndpointURI: "http://scout",
  keycloakConfig: keycloakConfig,
  csvTemplatesConfig: csvTemplatesConfig,
  flightPollingInterval: 30000,
  taskPollingInterval: 20000,
  visualChangeLiveTime: 20000,
  basicAuthConfig: "Simbirsoft:aichiCheec8iup",
  apiUrls: {
    orderForPeriod: 'https://ms-proposal4period',  // TODO: remove it, not uesed any more as DSandul said.
    tasks: 'https://arm-tza-backend/api',       // TODO: remove it, not uesed any more as DSandul said.
    flightsArm: 'https://arm-tza-backend/api/v1/flights', // TODO: remove it, not uesed any more as DSandul said.
  },
};
