import { Injectable } from '@angular/core';
import { AirlineJson } from '../json/airline.json';
import { Airline } from './airline';


@Injectable({ providedIn: 'root' })
export class AirlinesModelsFactory {

  public createAirline(json: AirlineJson): Airline {
    return new Airline(
      json.id,
      json.iata_code,
      json.name,
      json.full_name,
      json.code,
      json.organization_code,
      json.itn,
      json.iec,
    );
  }

}
