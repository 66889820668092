import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '../models/vehicle';
import { VehicleNamePipe } from './vehicle-name.pipe';


@Pipe({
  name: 'appVehiclesList',
})
export class VehiclesListPipe implements PipeTransform {

  constructor(
    private vehicleNamePipe: VehicleNamePipe,
  ) {
  }

  public transform(vehicles: Vehicle[] | null): string {
    return vehicles && vehicles.length
      ? vehicles.map(vehicle => this.vehicleNamePipe.transform(vehicle)).join(', ')
      : '-';
  }

}
