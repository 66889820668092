import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApplicationControlService {
  constructor(@Inject('window') private window: Window) {
  }

  public reload() {
    this.window.location.reload();
  }
}
