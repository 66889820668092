import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StorageModule } from '@ngx-pwa/local-storage';
import { ConfigService } from '@shared/services/config.service';
import { CoreInterceptor } from '@shared/services/core.interceptor';
import { KeycloakService } from '@shared/services/keycloak.service';
import { StartupService } from '@shared/services/system/startup.service';
import { KeycloakAngularModule } from 'keycloak-angular';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './app.shared';
import { NavBarModule } from './nav-bar/nav-bar.module';
import { AircraftsCommonModule } from './widgets/aircrafts/common/aircrafts.common.module';
import { AirlinesCommonModule } from './widgets/airlines/common/airlines.common.module';
import { AirportsCommonModule } from './widgets/airports/common/airports.common.module';
import { FillingPointsModule } from './widgets/filling-points/filling-points.module';
import { PlatformsCommonModule } from './widgets/platforms/common/platforms.common.module';
import { VehiclesCommonModule } from './widgets/vehicles/common/vehicles.common.module';

registerLocaleData(localeRu);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    NgxMaskModule.forRoot(options),
    SharedModule,
    KeycloakAngularModule,
    StorageModule.forRoot({ IDBNoWrap: true }),
    NavBarModule,
    PlatformsCommonModule,
    AirlinesCommonModule,
    VehiclesCommonModule,
    AircraftsCommonModule,
    AirportsCommonModule,
    FillingPointsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "ru",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [Injector],
      multi: true,
    },
    // Отключаем: требует доработки, в нынешнем виде глотает ошибки
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHttpInterceptor,
    //   multi: true,
    // },
    KeycloakService,
  ],
})

export class AppModule {
}

export function initializerFactory(injector: Injector): () => Promise<any> {
  return async () => {
    const keycloakService = injector.get(KeycloakService);

    // без логина будут недоступны необходимые для старта ресурсы
    let isLoggedIn = await keycloakService.init();
    if (!isLoggedIn) {
      await keycloakService.login();
    }

    await injector.get(ConfigService).load();

    // прикладные сервисы должны создаваться после конфига (он устанавливает apiUrl)
    return injector.get(StartupService).init();
  };
}
