import { BehaviorSubject } from 'rxjs';
import { isEqual } from 'lodash';


export interface StoreEntity {
  id: string;
}

export class StoreChangeExclusion {
  constructor(
    public path: string,
    public value: any,
  ) {
  }
}

export interface StoreChangesStates {
  [entityId: string]: {
    [key: string]: StoreChangesStateItem;
  };
}

export interface StoreChangesStateItem {
  changed: boolean;
  prevValue?: any;
  current?: any;
}

export class StoreCollection<T extends StoreEntity> {

  private static PATH_SEPARATOR = '.';

  private entities = new BehaviorSubject<Map<string, T> | null>(null);

  private prevEntities = new Map<string, T>();

  private changeExclusions = new Map<string, StoreChangeExclusion[]>();

  private resetPathsQueue = new Map<string, Set<string>>();

  public rewrite(entities: T[], resetChanges = true) {
    const newEntitiesIds = new Set(entities.map(entity => entity.id));

    if (resetChanges) {
      this.resetChanges();
    } else {
      this.rewriteEntityMap(this.prevEntities, newEntitiesIds);
    }

    this.rewriteEntityMap(this.entities.getValue() || new Map<string, T>(), newEntitiesIds);

    this.set(entities);
  }

  public rewriteEntity(entity: T) {
    const currentEntities = this.entities.getValue() || new Map<string, T>();
    const newEntities = new Map<string, T>(currentEntities);

    newEntities.set(entity.id, entity);
    this.changeExclusions.delete(entity.id);
    this.prevEntities.delete(entity.id);

    this.entities.next(newEntities);
  }

  public set(entities: T[]) {
    const currentEntities = this.entities.getValue() || new Map<string, T>();
    const newEntities = new Map<string, T>(currentEntities);

    entities.forEach(entity => {
      const prevEntity = currentEntities.get(entity.id);
      const resetPaths = this.resetPathsQueue.get(entity.id) || new Set<string>();

      if (prevEntity && !this.prevEntities.has(entity.id)) {
        this.prevEntities.set(entity.id, prevEntity);
      }

      newEntities.set(entity.id, entity);

      const lastPrevEntity = this.prevEntities.get(entity.id);
      if (!lastPrevEntity) {
        return;
      }

      resetPaths.forEach(path => {
        const parts = path.split(StoreCollection.PATH_SEPARATOR);
        const lastField = parts.pop();
        let parent: any = lastPrevEntity;
        parts.forEach(field => {
          if (!parent[field]) {
            parent[field] = {} as any;
          }

          parent = parent[field];
        });

        parent[lastField] = this.getValueByPath(entity, path);
      });
      this.prevEntities.set(entity.id, lastPrevEntity);
      resetPaths.clear();
      this.resetPathsQueue.set(entity.id, resetPaths);
    });

    this.entities.next(newEntities);
  }

  public all() {
    return this.entities.asObservable();
  }

  public get(id: string) {
    const currentEntities = this.entities.getValue() || new Map<string, T>();

    return currentEntities.get(id) || null;
  }

  public delete(id: string) {
    const currentEntities = this.entities.getValue() || new Map<string, T>();
    const newEntities = new Map<string, T>(currentEntities);
    newEntities.delete(id);
    this.changeExclusions.delete(id);
    this.prevEntities.delete(id);
    this.entities.next(newEntities);
  }

  public isEmpty() {
    return this.entities.getValue() === null;
  }

  public getPrev(id: string) {
    return this.prevEntities.get(id);
  }

  public getAllPrev() {
    return new Map<string, T>(this.prevEntities);
  }

  public isChanged(id: string, path: string | null = null) {
    const currentEntities = this.entities.getValue();
    if (!currentEntities || !currentEntities.has(id)) {
      return false;
    }

    const hasEntityChanges = this.prevEntities.has(id);

    if (!hasEntityChanges) {
      return false;
    }

    if (!path) {
      return !isEqual(currentEntities.get(id), this.prevEntities.get(id));
    }

    const currentValue = this.getValueByPath(currentEntities.get(id), path);
    const excludes = this.changeExclusions.get(id) || [];
    if (excludes.some(exclude => exclude.path === path && isEqual(exclude.value, currentValue))) {
      return false;
    }

    const prevValue = this.getPrevValueByPath(id, path);

    return !isEqual(currentValue, prevValue);
  }

  public excludeChange(id: string, path: string, value: any) {
    const excludes = this.changeExclusions.get(id) || [];
    excludes.push(new StoreChangeExclusion(path, value));

    this.changeExclusions.set(id, excludes);
  }

  public deleteChangeExclusion(id: string, path: string, value: any) {
    const excludes = this.changeExclusions.get(id) || [];

    this.changeExclusions.set(id, excludes.filter(exclude => exclude.path === path && isEqual(exclude.value, value)));
  }

  public resetEntityChanges(id: string) {
    this.prevEntities.delete(id);
  }

  public resetChanges() {
    this.changeExclusions.clear();
    this.prevEntities.clear();
  }

  public reset() {
    this.resetChanges();
    this.entities.next(null);
  }

  public lastValue() {
    return this.entities.getValue();
  }

  public getPrevValueByPath(id: string, path: string) {
    const entity = this.prevEntities.get(id);
    if (!entity) {
      return undefined;
    }

    return this.getValueByPath(entity, path);
  }

  public getAllChanges(paths: string[]): StoreChangesStates {
    const result: StoreChangesStates = {};
    const entities = this.lastValue();
    if (!entities) {
      return result;
    }

    entities
      .forEach(entity => paths
        .forEach(path => {
          const item: StoreChangesStateItem = {
            changed: this.isChanged(entity.id, path),
            current: this.getValueByPath(entity, path),
          };

          if (item.changed) {
            item.prevValue = this.getPrevValueByPath(entity.id, path);
          }

          if (!result[entity.id]) {
            result[entity.id] = {};
          }

          result[entity.id][path] = item;
        }),
      );

    return result;
  }

  public resetPrevValue(id: string, path: string): void {
    const entityFields = this.resetPathsQueue.get(id) || new Set<string>();
    entityFields.add(path);
  }

  private getValueByPath(entity: T, path: string) {
    const pathParts = path.split(StoreCollection.PATH_SEPARATOR);

    let value: any = entity;
    for (let i = 0; i < pathParts.length; i++) {
      value = value[pathParts[i]];

      if (value === undefined || value === null) {
        break;
      }
    }

    return value;
  }

  private rewriteEntityMap(entitiesMap: Map<string, T>, newEntitiesIds: Set<string>): void {
    [...entitiesMap.keys()]
      .filter(id => !newEntitiesIds.has(id))
      .forEach(id => entitiesMap.delete(id));
  }
}
