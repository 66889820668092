import { Directive, OnInit, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[appAutofocus]",
})
export class AutofocusDirective implements OnInit {
  @Input()
  public appAutofocus?: boolean;

  constructor(private elementRef: ElementRef) {
  };

  ngOnInit(): void {
    if (this.appAutofocus !== false) {
      this.elementRef.nativeElement.focus();
    }
  }
}
