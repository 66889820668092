import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import { DateTimeFormatter } from '@shared/services/utility/date-time-formatter.service';
import * as moment from 'moment-timezone';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { VehicleTaskReasonsOfRejectEnum } from './enum/vehicle-task-reasons-of-reject.enum';
import { VehicleTaskStatesEnum } from './enum/vehicle-task-states.enum';
import { VehicleTaskStatusesEnum } from './enum/vehicle-task-statuses.enum';
import { VehicleTasksTypesEnum } from './enum/vehicle-tasks-types.enum';
import { TaskFillingHistoryItemJson } from './json/task-filling-history-item.json-interface';
import { TaskFuelingHistoryItemJson } from './json/task-fueling-history-item.json-interface';
import { VehicleTaskFillingJson } from './json/vehicle-task-filling.json-interface';
import { VehicleTaskFuelingRequestJson } from './json/vehicle-task-fueling-request.json-interface';
import { VehicleTaskFuelingJson } from './json/vehicle-task-fueling.json-interface';
import { TaskFuelingHistoryItem } from './models/task-fueling-history-item';
import { TaskHistoryItem } from './models/task-history-item.interface';
import { VehicleTaskFilling } from './models/vehicle-task-filling';
import { VehicleTaskFueling } from './models/vehicle-task-fueling';
import { VehicleTask } from './models/vehicle-task.interface';
import { VehiclesTasksModelsFactory } from './models/vehicles-tasks-models.factory';


@Injectable()
export class VehiclesTasksService {

  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private currentAirportService: CurrentAirportService,
    private vehiclesTasksModelsFactory: VehiclesTasksModelsFactory,
    private dateTimeFormatter: DateTimeFormatter,
    configService: ConfigService,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public loadFillingTasks(startDate: moment.Moment, endDate: moment.Moment, excludeStatuses: VehicleTaskStatusesEnum[] = []) {
    const query = this.getRSqlOfLoadTasks(startDate, endDate, excludeStatuses);

    return this
      .httpClient
      .get<VehicleTaskFillingJson[]>(`${ this.apiUrl }/v1/fillings/${ query }`)
      .pipe(
        map(tasksJson => tasksJson.map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json))),
      );
  }

  public loadFuelingTasks(startDate: moment.Moment, endDate: moment.Moment, excludeStatuses: VehicleTaskStatusesEnum[] = []) {
    const query = this.getRSqlOfLoadTasks(startDate, endDate, excludeStatuses);

    return this
      .httpClient
      .get<VehicleTaskFuelingJson[]>(`${ this.apiUrl }/v1/refuelings/${ query }`)
      .pipe(
        // todo: убрать, когда будут приходить валидные данные
        map(tasksJson => tasksJson.filter(taskJson => {
          const hasFlight = !!taskJson.flight;
          if (!hasFlight) {
            console.log('filter invalid task:', taskJson);
          }

          return hasFlight;
        })),
        map(tasksJson => tasksJson.map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json))),
      );

  }

  public loadFlightTasks(startDate: moment.Moment, endDate: moment.Moment, excludeStatuses: VehicleTaskStatusesEnum[] = []) {
    let flightsQuery = `departureDateTime>${ this.dateTimeFormatter.toFullIsoWithMilliseconds(startDate) }`;
    flightsQuery = `${ flightsQuery }%3BdepartureDateTime<${ this.dateTimeFormatter.toFullIsoWithMilliseconds(endDate) }`;

    let taskQuery = `airportIataCode==${ this.currentAirportService.currentIATA }`;
    excludeStatuses.forEach(status => taskQuery = `${ taskQuery }%3Bstatus!=${ status }`);

    return this
      .httpClient
      .get<VehicleTaskFuelingJson[]>(`${ this.apiUrl }/v1/refuelings/flights/${ flightsQuery }/refuelings/${ taskQuery }`)
      .pipe(
        // todo: убрать, когда будут приходить валидные данные
        map(tasksJson => tasksJson.filter(taskJson => {
          const hasFlight = !!taskJson.flight;
          if (!hasFlight) {
            console.log('filter invalid task:', taskJson);
          }

          return hasFlight;
        })),
        map(tasksJson => tasksJson.map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json))),
      );

  }

  public loadAllFuelingTasks(startDate: moment.Moment, endDate: moment.Moment, excludeStatuses: VehicleTaskStatusesEnum[] = []) {
    return forkJoin({
      flights: this.loadFlightTasks(startDate, endDate, excludeStatuses),
      tasks: this.loadFuelingTasks(startDate, endDate, excludeStatuses),
    })
      .pipe(
        map(tasks => {
          const result = new Map<string, VehicleTaskFueling>();
          [...tasks.flights, ...tasks.tasks].forEach(task => result.set(task.id, task));

          return result.values();
        }),
      );
  }

  public loadTasks(startDate: moment.Moment, endDate: moment.Moment, excludeStatuses: VehicleTaskStatusesEnum[] = []) {
    return forkJoin({
      [VehicleTasksTypesEnum.FILLING]: this.loadFillingTasks(startDate, endDate, excludeStatuses),
      [VehicleTasksTypesEnum.FUELING]: this.loadAllFuelingTasks(startDate, endDate, excludeStatuses),
    })
      .pipe(
        map(tasks => [...tasks[VehicleTasksTypesEnum.FILLING], ...tasks[VehicleTasksTypesEnum.FUELING]] as VehicleTask[]),
      );
  }

  public createTaskFilling(
    vehicleId: string,
    fillingPlanPointId: number | null,
    planStart: string,
    planFinish: string,
    fillingPlanStart: string | null,
    fillingPlanFinish: string | null,
    planFuelMass: number | null,
  ) {
    return this
      .httpClient
      .post<VehicleTaskFillingJson>(`${ this.apiUrl }/v1/fillings/filling`, {
        local_create_date_time: this.dateTimeFormatter.toFullIsoWithMilliseconds(new Date()),
        airport_iata_code: this.currentAirportService.currentIATA,
        airport_id: this.currentAirportService.currentAirport.id,
        created_by: 'dispatcher', // todo: унифицровать enum с рейсами
        plan_start_date_time: planStart,
        plan_finish_date_time: planFinish,
        filling_plan_start_date_time: fillingPlanStart,
        filling_plan_finish_date_time: fillingPlanFinish,
        plan_mass: planFuelMass,
        state: null,
        status: VehicleTaskStatusesEnum.NEW,
        vehicle_id: vehicleId,
        filling_plan_point_id: fillingPlanPointId,
      })
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json)),
      );
  }

  public acceptDraftTaskFilling(task: VehicleTaskFilling) {
    return this
      .httpClient
      .put<VehicleTaskFillingJson>(`${ this.apiUrl }/v1/fillings/filling/${ task.id }`, this.getFillingPathRequestData(task, {
        status: VehicleTaskStatusesEnum.NEW,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json)),
      );
  }

  public revertTaskFilling(
    task: VehicleTaskFilling,
    targetStatus: VehicleTaskStatusesEnum,
    planStart: string,
    planFinish: string,
    fillingPlanPointId: number | null,
    fillingPlanStart: string,
    fillingPlanFinish: string,
    planFuelMass: number,
    vehicleId: string,
  ) {
    return this
      .httpClient
      .put<VehicleTaskFillingJson>(`${ this.apiUrl }/v1/fillings/filling/${ task.id }`, this.getFillingPathRequestData(task, {
        plan_start_date_time: planStart,
        plan_finish_date_time: planFinish,
        filling_plan_point_id: fillingPlanPointId,
        filling_plan_start_date_time: fillingPlanStart,
        filling_plan_finish_date_time: fillingPlanFinish,
        plan_mass: planFuelMass,
        status: targetStatus,
        vehicle_id: vehicleId,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json)),
      );
  }

  public rejectDraftTaskFilling(task: VehicleTaskFilling) {
    return this
      .httpClient
      .put<VehicleTaskFillingJson>(`${ this.apiUrl }/v1/fillings/filling/${ task.id }`, this.getFillingPathRequestData(task, {
        status: VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_DISPATCHER,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json)),
      );
  }

  public cancelTaskFilling(task: VehicleTaskFilling) {
    return this
      .httpClient
      .put<VehicleTaskFillingJson>(`${ this.apiUrl }/v1/fillings/filling/${ task.id }`, this.getFillingPathRequestData(task, {
        status: VehicleTaskStatusesEnum.CANCELED,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json)),
      );
  }

  public updateTaskFilling(
    task: VehicleTaskFilling,
    planStart: string,
    planFinish: string,
    fillingPlanPointId: number | null,
    fillingPlanStart: string,
    fillingPlanFinish: string,
    planFuelMass: number,
    vehicleId: string,
  ) {
    return this
      .httpClient
      .put<VehicleTaskFillingJson>(`${ this.apiUrl }/v1/fillings/filling/${ task.id }`, this.getFillingPathRequestData(task, {
        plan_start_date_time: planStart,
        plan_finish_date_time: planFinish,
        filling_plan_point_id: fillingPlanPointId,
        filling_plan_start_date_time: fillingPlanStart,
        filling_plan_finish_date_time: fillingPlanFinish,
        plan_mass: planFuelMass,
        vehicle_id: vehicleId,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFilling(json)),
      );
  }

  public createTaskFueling(
    flightId: string,
    planStart: string | null = null,
    planFinish: string | null = null,
    fuelingPlanStart: string | null = null,
    fuelingPlanFinish: string | null = null,
    planFuelMass: number | null = null,
    vehicleId: string | null = null,
  ) {
    return this
      .httpClient
      .post<VehicleTaskFuelingJson>(`${ this.apiUrl }/v1/refuelings/flight/${ flightId }/refueling`, {
        plan_start_date_time: planStart,
        plan_finish_date_time: planFinish,
        refueling_plan_start_date_time: fuelingPlanStart,
        refueling_plan_finish_date_time: fuelingPlanFinish,
        plan_mass: planFuelMass,
        flight: { id: flightId },
        vehicle_id: vehicleId,
        status: VehicleTaskStatusesEnum.NEW,
        state: null,
        created_by: 'dispatcher',
        local_create_date_time: this.dateTimeFormatter.toFullIsoWithMilliseconds(new Date()),
      })
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json)),
      );
  }

  public acceptDraftTaskFueling(task: VehicleTaskFueling) {
    return this
      .httpClient
      .put<VehicleTaskFuelingJson>(`${ this.apiUrl }/v1/refuelings/refueling/${ task.id }`, this.getFuelingPathRequestData(task, {
        status: VehicleTaskStatusesEnum.NEW,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json)),
      );
  }

  public revertTaskFueling(
    task: VehicleTaskFueling,
    targetStatus: VehicleTaskStatusesEnum,
    planStart: string,
    planFinish: string,
    fuelingPlanStart: string,
    fuelingPlanFinish: string,
    planFuelMass: number,
    vehicleId: string,
  ) {
    return this
      .httpClient
      .put<VehicleTaskFuelingJson>(`${ this.apiUrl }/v1/refuelings/refueling/${ task.id }`, this.getFuelingPathRequestData(task, {
        plan_start_date_time: planStart,
        plan_finish_date_time: planFinish,
        refueling_plan_start_date_time: fuelingPlanStart,
        refueling_plan_finish_date_time: fuelingPlanFinish,
        plan_mass: planFuelMass,
        status: targetStatus,
        vehicle_id: vehicleId,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json)),
      );
  }

  public rejectDraftTaskFueling(task: VehicleTaskFueling) {
    return this
      .httpClient
      .put<VehicleTaskFuelingJson>(`${ this.apiUrl }/v1/refuelings/refueling/${ task.id }`, this.getFuelingPathRequestData(task, {
        status: VehicleTaskStatusesEnum.REJECTED_DRAFT_BY_DISPATCHER,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json)),
      );
  }

  public cancelTaskFueling(task: VehicleTaskFueling) {
    return this
      .httpClient
      .put<VehicleTaskFuelingJson>(`${ this.apiUrl }/v1/refuelings/refueling/${ task.id }`, this.getFuelingPathRequestData(task, {
        status: VehicleTaskStatusesEnum.CANCELED,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json)),
      );
  }

  public updateTaskFueling(
    task: VehicleTaskFueling,
    planStart: string,
    planFinish: string,
    fuelingPlanStart: string,
    fuelingPlanFinish: string,
    planFuelMass: number,
    vehicleId: string,
  ) {
    return this
      .httpClient
      .put<VehicleTaskFuelingJson>(`${ this.apiUrl }/v1/refuelings/refueling/${ task.id }`, this.getFuelingPathRequestData(task, {
        plan_start_date_time: planStart,
        plan_finish_date_time: planFinish,
        refueling_plan_start_date_time: fuelingPlanStart,
        refueling_plan_finish_date_time: fuelingPlanFinish,
        plan_mass: planFuelMass,
        vehicle_id: vehicleId,
      }))
      .pipe(
        map(json => this.vehiclesTasksModelsFactory.createTaskFueling(json)),
      );
  }

  public getTaskHistory(task: VehicleTask) {
    return task.type === VehicleTasksTypesEnum.FILLING
      ? this.getTaskFillingHistory(task.id)
      : this.getTaskFuelingHistory(task.id);
  }

  public getTaskFillingHistory(taskId: string) {
    return this
      .httpClient
      .get<TaskFillingHistoryItemJson[]>(`${ this.apiUrl }/v1/fillings/filling/${ taskId }/history`)
      .pipe(
        map(json => json.map(itemJson => this.vehiclesTasksModelsFactory.createTaskFillingHistoryItem(itemJson))),
        map(items => this.sortTaskHistory(items)),
      );
  }

  public getTaskFuelingHistory(taskId: string) {
    return this
      .httpClient
      .get<TaskFuelingHistoryItemJson[]>(`${ this.apiUrl }/v1/refuelings/refueling/${ taskId }/history`)
      .pipe(
        map(json => json.map(itemJson => this.vehiclesTasksModelsFactory.createTaskFuelingHistoryItem(itemJson))),
        map(items => this.sortTaskHistory(items) as TaskFuelingHistoryItem[]),
      );
  }

  public getTaskFillingReasonOfReject(task: VehicleTaskFilling) {
    if (task.status !== VehicleTaskStatusesEnum.REJECTED_BY_DRIVER) {
      return of(null);
    }

    return this
      .httpClient
      .get<VehicleTaskReasonsOfRejectEnum>(`${ this.apiUrl }/v1/fillings/filling/${ task.id }/reason`);
  }

  public getTaskFuelingReasonOfReject(task: VehicleTaskFueling) {
    if (
      task.status !== VehicleTaskStatusesEnum.REJECTED_BY_DRIVER &&
      !(task.status === VehicleTaskStatusesEnum.PROCESSING && task.state === VehicleTaskStatesEnum.PAUSE_FUELING)
    ) {
      return of(null);
    }

    return this
      .httpClient
      .get<VehicleTaskReasonsOfRejectEnum>(`${ this.apiUrl }/v1/refuelings/refueling/${ task.id }/reason`);
  }

  public loadReasonsOfRejectAndPauseFuelingTasks(tasksIds: string[]) {
    const sources: { [id: string]: Observable<VehicleTaskReasonsOfRejectEnum> } = {};
    tasksIds.forEach(id => sources[id] = this.loadReasonsOfRejectAndPauseFuelingTask(id));

    return forkJoin(sources)
      .pipe(
        map(reasons => {
          const result = new Map<string, VehicleTaskReasonsOfRejectEnum>();
          tasksIds.forEach(id => result.set(id, reasons[id]));

          return result;
        }),
      );
  }

  public loadReasonsOfRejectAndPauseFuelingTask(id: string) {
    return this
      .httpClient
      .get<VehicleTaskReasonsOfRejectEnum>(`${ this.apiUrl }/v1/refuelings/refueling/${ id }/reason`);
  }

  public canChangeVehicle(task: VehicleTask) {
    return task.status !== VehicleTaskStatusesEnum.PROCESSING || !task.state;
  }

  public isNeedApprovedTask(status: VehicleTaskStatusesEnum): boolean {
    return status === VehicleTaskStatusesEnum.NEW
      || status === VehicleTaskStatusesEnum.ACCEPTED
      || status === VehicleTaskStatusesEnum.PROCESSING
      || status === VehicleTaskStatusesEnum.DONE;
  }

  private sortTaskHistory(items: TaskHistoryItem[]) {
    return items.sort((a: TaskHistoryItem, b: TaskHistoryItem) => moment(a.dateTime).diff(b.dateTime, 'milliseconds'));
  }

  private getRSqlOfLoadTasks(startDate: moment.Moment, endDate: moment.Moment, excludeStatuses: VehicleTaskStatusesEnum[] = []) {
    const start = this.dateTimeFormatter.toFullIsoWithMilliseconds(startDate);
    const end = this.dateTimeFormatter.toFullIsoWithMilliseconds(endDate);

    let query = `%28planStartDateTime>${ start }%2CfactStartDateTime>${ start }%29`;
    query = `${ query }%3B%28planFinishDateTime<${ end }%2CfactFinishDateTime<${ end }%29`;
    query = `${ query }%3BairportIataCode==${ this.currentAirportService.currentIATA }`;

    excludeStatuses.forEach(status => query = `${ query }%3Bstatus!=${ status }`);

    return query;
  }

  private getFillingPathRequestData(task: VehicleTaskFilling, data: Partial<VehicleTaskFillingJson>): VehicleTaskFillingJson {
    return {
      id: task.id,
      airport_id: task.airportId,
      filling_plan_point_id: task.fillingPlanPointId,
      filling_fact_point_id: task.fillingFactPointId,
      vehicle_id: task.vehicleId,
      airport_iata_code: task.airportIataCode,
      number: task.number,
      local_create_date_time: task.localCreateDate,
      driver_login: task.driver,
      status: task.status,
      state: task.state,
      created_by: task.createdBy,
      approved_by_driver: task.approvedByDriver,
      plan_start_date_time: task.planStartDate,
      plan_finish_date_time: task.planFinishDate,
      fact_start_date_time: task.factStartDate,
      fact_finish_date_time: task.factFinishDate,
      filling_plan_start_date_time: task.fillingPlanStartDate,
      filling_plan_finish_date_time: task.fillingFactFinishDate,
      filling_fact_start_date_time: task.fillingFactStartDate,
      filling_fact_finish_date_time: task.fillingFactFinishDate,
      plan_mass: task.planMass,
      fact_mass: task.factMass,
      plan_volume: task.planVolume,
      fact_volume: task.factVolume,
      ...data,
    };
  }

  private getFuelingPathRequestData(task: VehicleTaskFueling, data: Partial<VehicleTaskFuelingJson>): VehicleTaskFuelingRequestJson {
    return {
      id: task.id,
      bch_task_id: task.blockchainTaskid,
      airport_id: task.airportId,
      vehicle_id: task.vehicleId,
      platform_id: task.platformId,
      product_id: task.productId,
      payment_type_id: task.paymentTypeId,
      airport_iata_code: task.airportIataCode,
      flight: {
        id: task.flight.id,
      },
      number: task.number,
      local_create_date_time: task.localCreateDate,
      driver_login: task.driver,
      is_blockchain: task.isBlockchain,

      status: task.status,
      state: task.state,
      created_by: task.createdBy,
      approved_by_driver: task.approvedByDriver,
      approved_by_captain: task.approvedByCaptain,

      plan_start_date_time: task.planStartDate,
      plan_finish_date_time: task.planFinishDate,
      fact_start_date_time: task.factStartDate,
      fact_finish_date_time: task.factFinishDate,
      refueling_plan_start_date_time: task.fuelingPlanStartDate,
      refueling_plan_finish_date_time: task.fuelingPlanFinishDate,
      refueling_fact_start_date_time: task.fuelingFactStartDate,
      refueling_fact_finish_date_time: task.fuelingFactFinishDate,

      plan_mass: task.planMass,
      fact_mass: task.factMass,
      plan_volume: task.planVolume,
      fact_volume: task.factVolume,
      ...data,
    };
  }

}
