import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentAirportService } from '@shared/services/business/current-airport.service';
import { ConfigService } from '@shared/services/config.service';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';
import { RsqlFilter, RsqlOperator } from '../../../applications/models/rsql-filter';
import { AirportsStore } from '../../airports/common/airports.store';
import { ANTI_ICING_FLUIDS_LABELS, AntiIcingFluidsEnum } from '../../anti-icing-fluids/common/enums/anti-icing-fluids.enum';
import { PlatformsStore } from '../../platforms/common/platforms.store';
import { FuelUnitsEnum } from './enums/fuel-units.enum';
import { FlightJson } from './json/flight.json';
import { Flight } from './models/flight';
import { FlightsFactory } from './models/flights.factory';
import { PathedFlightFields } from './types/pathed-flight-fields.type';


@Injectable()
export class FlightsService {

  private apiUrl: string;

  constructor(
    configService: ConfigService,
    private httpClient: HttpClient,
    private flightsFactory: FlightsFactory,
    private currentAirportService: CurrentAirportService,
    private platformsStore: PlatformsStore,
    private airportsStore: AirportsStore,
  ) {
    this.apiUrl = configService.apiUrl;
  }

  public getFlight(id: string) {
    return this.httpClient
      .get<FlightJson>(`${ this.apiUrl }/v1/flights/flight/${ id }`)
      .pipe(
        map(json => this.flightsFactory.createFlight(json)),
      );
  }

  public loadFlights(start: moment.Moment, finish: moment.Moment) {
    const query = RsqlFilter.EMPTY
      .set('departureDateTime', RsqlOperator.greater, start)
      .set('departureDateTime', RsqlOperator.lesser, finish);

    return this.getFlightsByRsql(query);
  }

  public loadFlightsChanges(from: moment.Moment) {
    const query = RsqlFilter.EMPTY
      .set('updatedDateTime', RsqlOperator.greaterOrEqual, from);

    return this.getFlightsByRsql(query);
  }

  public pathFlight(flightId: string, field: PathedFlightFields, value: any) {
    return this
      .httpClient
      .patch<FlightJson>(`${this.apiUrl}/v1/flights/flight/${flightId}`, this.getPatchedData(field, value))
      .pipe(
        map(json => this.flightsFactory.createFlight(json)),
      );
  }

  public sortFlights(flights: Flight[]): Flight[] {
    return flights.sort((a: Flight, b: Flight) => moment(a.departure.planDateTime).diff(b.departure.planDateTime, 'milliseconds'));
  }

  private getFlightsByRsql(rsqlQuery: RsqlFilter) {
    // todo: унифицировать Rsql
    rsqlQuery.set('departureAirportIataCode', RsqlOperator.equals, this.currentAirportService.currentIATA);

    return this.httpClient
      .get<FlightJson[]>(`${ this.apiUrl }/v1/flights/${ encodeURIComponent(rsqlQuery.toString()) }`)
      .pipe(
        map(flights => flights.map(json => this.flightsFactory.createFlight(json))),
      );
  }

  private getPatchedData(field: PathedFlightFields, value: any): Partial<FlightJson> {
    switch (field) {
    case 'arrivalDateTime':
      return { arrival_date_time: value };

    case 'departureDateTime':
      return { departure_date_time: value };

    case 'tgoPlanStart':
      return { tgo_start_plan_date_time: value };

    case 'tgoPlanFinish':
      return { tgo_finish_plan_date_time: value };

    case 'fuelPlan':
      return { fuel: { quantity: value, unit: FuelUnitsEnum.KG } };

    case 'platform':
      const platform = this.platformsStore.findById(value);
      return {
        platform: {
          id: platform ? platform.id : 0,
          name: platform ? platform.name : '-',
          airport_iata_code: platform ? platform.airportIataCode : '',
          has_dispenser: platform ? platform.hasDispenser : null,
          latitude: platform ? platform.latitude : null,
          longitude: platform ? platform.longitude : null,
        },
      };

    case 'antiIcingFluid':
      const antiIcingFluid = value || AntiIcingFluidsEnum.NO;
      return { anti_icing_fluid: { id: antiIcingFluid, name: <string>ANTI_ICING_FLUIDS_LABELS[antiIcingFluid] } };

    case 'aircraft':
      return { aircraft: value };

    case 'number':
      return { number: value };

    case 'destination':
      const result: Partial<FlightJson> = { destination: value };

      const airport = this.airportsStore.findByCityName(value);
      if (airport) {
        result.arrival_airport_name = airport.name;
        result.arrival_airport_iata_code = airport.iataCode;
      }

      return result;
    }

    return null;
  }
}
