import { Injectable } from '@angular/core';
import { returnVoid } from '@shared/functions/return-void.function';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AirlinesService } from './airlines.service';
import { Airline } from './models/airline';


@Injectable({ providedIn: 'root' })
export class AirlinesStore {

  private airlinesSubject = new BehaviorSubject<Airline[]>([]);

  private indexById = new Map<number, Airline>();

  private indexByName = new Map<string, Airline>();

  constructor(
    private airlinesService: AirlinesService,
  ) {
  }

  public init(): Observable<void> {
    return this
      .airlinesService
      .load()
      .pipe(
        tap(airline => this.set(airline)),
        map(returnVoid),
      );
  }

  public get(): Airline[] {
    return this.airlinesSubject.getValue();
  }

  public findById(id: number): Airline | null {
    return this.indexById.get(id) || null;
  }

  public findByName(name: string): Airline | null {
    return this.indexByName.get(name) || null;
  }

  public changes(): Observable<Airline[]> {
    return this.airlinesSubject.asObservable();
  }

  public set(airlines: Airline[]): void {
    this.indexById = new Map<number, Airline>();
    this.indexByName = new Map<string, Airline>();

    airlines.forEach(airline => {
      this.indexById.set(airline.id, airline);
      this.indexByName.set(airline.name, airline);
    });

    this.airlinesSubject.next(airlines);
  }

}

