import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Airport } from '@shared/models/airport.model';
import { ConfigService } from '@shared/services/config.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AirportsService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private config$: ConfigService,
  ) {
    this.apiUrl = config$.apiUrl;
  }

  getAirportsByIATACodes(codes: string[]): Observable<Airport[]> {
    return forkJoin(
      codes
        .map(code => this
          .getAirportByIATA(code)
          .pipe(
            catchError(code => {
              console.log(`Airport "${ code }" is not available`);

              return of(null);
            }),
          ),
        ),
    )
      .pipe(
        map(airports => airports.filter(airport => !!airport) as Airport[]),
      );
  }

  getAirportByIATA(code: string): Observable<Airport> {
    return this.http.get(`${ this.apiUrl }/v1/catalog/airports/airport-by-iata/${ code }`)
      .pipe(map((result: Airport) => result));
  }
}
