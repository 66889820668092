export class FillingPoint {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public airportIataCode: string,
    public isMilitary: boolean,
  ) {
  }
}
