import { Injectable, OnDestroy } from '@angular/core';
import { returnVoid } from '@shared/functions/return-void.function';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { VehicleStatusesEnum } from '../enum/vehicle-statuses.enum';
import { Vehicle } from '../models/vehicle';
import { VehicleRemainingFuel } from '../models/vehicle-remaining-fuel';
import { VehiclesModelsFactory } from '../models/vehicles-models.factory';
import { VehiclesService } from '../vehicles.service';


@Injectable({ providedIn: 'root' })
export class VehiclesStore implements OnDestroy {

  private static STATUSES_POOLING_INTERVAL = 30000;

  private static REMAINING_FUEL_POOLING_INTERVAL = 30000;

  private vehicles = new BehaviorSubject<Vehicle[]>([]);

  private indexById = new Map<string, Vehicle>();

  private statuses = new BehaviorSubject<Map<string, VehicleStatusesEnum>>(new Map<string, VehicleStatusesEnum>());

  private remainingFuels = new BehaviorSubject<Map<string, VehicleRemainingFuel>>(new Map<string, VehicleRemainingFuel>());

  private destroy = new Subject<void>();

  constructor(
    private vehiclesService: VehiclesService,
    private vehiclesModelsFactory: VehiclesModelsFactory,
  ) {
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public init(): Observable<void> {
    this.initStatusesPooling();
    this.initRemainingFuelPooling();

    return this
      .vehiclesService
      .loadVehicles()
      .pipe(
        tap(vehicles => this.set(vehicles)),
        map(returnVoid),
      );
  }

  public get(): Vehicle[] {
    return this.vehicles.getValue();
  }

  public findById(id: string): Vehicle | null {
    return this.indexById.get(id) || null;
  }

  public changes(): Observable<Vehicle[]> {
    return this.vehicles.asObservable();
  }

  public set(vehicles: Vehicle[]): void {
    this.indexById = new Map<string, Vehicle>(vehicles.map(vehicle => [vehicle.id, vehicle]));
    this.vehicles.next(vehicles);
  }

  public getStatuses(vehicles: Vehicle[]): Observable<Map<string, VehicleStatusesEnum>> {
    return this
      .statuses
      .pipe(
        takeUntil(this.destroy),
        map(statuses => this.createVehiclesStatuses(vehicles, statuses)),
      );
  }

  public getStatus(id: string) {
    const result = new BehaviorSubject<VehicleStatusesEnum>(VehicleStatusesEnum.NOT_READY);

    this
      .statuses
      .pipe(
        takeUntil(this.destroy),
        map(statuses => statuses.get(id) || VehicleStatusesEnum.NOT_READY),
        filter(status => result.getValue() !== status),
      )
      .subscribe(status => result.next(status));

    return result.asObservable();
  }

  public getRemainingFuels(vehicles: Vehicle[]) {
    return this
      .remainingFuels
      .pipe(
        takeUntil(this.destroy),
        map(statuses => this.createRemainingFuels(vehicles, statuses)),
      );
  }

  public getRemainingFuel(id: string) {
    const result = new BehaviorSubject<VehicleRemainingFuel>(this.vehiclesModelsFactory.createDefaultVehicleRemainingFuel());

    this
      .remainingFuels
      .pipe(
        takeUntil(this.destroy),
        map(remainingFuels => remainingFuels.get(id) || this.vehiclesModelsFactory.createDefaultVehicleRemainingFuel()),
        filter(remainingFuel => !result.getValue().isEqual(remainingFuel)),
      )
      .subscribe(remainingFuel => result.next(remainingFuel));

    return result.asObservable();
  }

  private initStatusesPooling() {
    timer(0, VehiclesStore.STATUSES_POOLING_INTERVAL)
      .pipe(
        takeUntil(this.destroy),
        switchMap(() => this.vehiclesService.loadVehicleStatuses()),
      )
      .subscribe(statuses => this.statuses.next(statuses));
  }

  private initRemainingFuelPooling() {
    timer(0, VehiclesStore.REMAINING_FUEL_POOLING_INTERVAL)
      .pipe(
        takeUntil(this.destroy),
        switchMap(() => this.vehiclesService.loadVehiclesRemainingFuel()),
      )
      .subscribe(remainingFuels => this.remainingFuels.next(remainingFuels));
  }

  private createVehiclesStatuses(vehicles: Vehicle[], statuses: Map<string, VehicleStatusesEnum>): Map<string, VehicleStatusesEnum> {
    const vehiclesStatuses = new Map<string, VehicleStatusesEnum>();
    vehicles
      .forEach(vehicle => vehiclesStatuses.set(
        vehicle.id,
        statuses.get(vehicle.id) || VehicleStatusesEnum.NOT_READY,
      ));

    return vehiclesStatuses;
  }

  private createRemainingFuels(vehicles: Vehicle[], fuels: Map<string, VehicleRemainingFuel>) {
    const remainingFuels = new Map<string, VehicleRemainingFuel>();
    vehicles
      .forEach(vehicle => remainingFuels.set(
        vehicle.id,
        fuels.get(vehicle.id) || this.vehiclesModelsFactory.createDefaultVehicleRemainingFuel(),
      ));

    return remainingFuels;
  }

}
