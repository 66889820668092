export enum VehicleStatusesEnum {
  BUSY = 'BUSY',
  NOT_READY = 'NOT_READY',
  READY = 'READY',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export const VEHICLE_STATUSES_LABELS = {
  [VehicleStatusesEnum.BUSY] : 'в работе',
  [VehicleStatusesEnum.NOT_READY] : 'не готов',
  [VehicleStatusesEnum.READY] : 'готов',
  [VehicleStatusesEnum.NOT_ALLOWED] : 'не допущен',
};
