import { Component, HostListener } from '@angular/core';
import { ToastService } from "../../services/toast.service";
import { NgbAlertConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  providers: [NgbAlertConfig],
})
export class ToastComponent {
  constructor(
    alertConfig: NgbAlertConfig,
    public toastService: ToastService,
  ) {
    alertConfig.dismissible = false;
  }


  @HostListener('click', ["$event"])
  public stopClickPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

}
