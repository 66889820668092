import { Pipe, PipeTransform } from "@angular/core";
import { Airport } from "@shared/models/airport.model";

@Pipe({
  name: "airportName",
})
export class AirportNamePipe implements PipeTransform {

  transform(airport: Airport): string {
    let [first, code, last] = airport.name.split(", ");
    let name = last && airport.city_name && first.toLocaleLowerCase() === airport.city_name.toLocaleLowerCase() ? last : first;
    return titleCase(name);
  }
}

function titleCase(title: string): string {
  function capitalize(word: string) {
    return word.charAt(0).toLocaleUpperCase() + word.substr(1);
  }

  title = title.toLocaleLowerCase();
  title = title.split("-").map(capitalize).join("-");
  return title.split(" ").map(capitalize).join(" ");
}
