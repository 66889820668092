import { Injectable } from '@angular/core';
import { RemainingFuelJson } from '../interfaces/remaining-fuel.json-interface';
import { VehicleCoordinatesJson } from '../interfaces/vehicle-coordinates.json-interface';
import { VehicleJson } from '../interfaces/vehicle.json-interface';
import { Vehicle } from './vehicle';
import { VehicleCoordinates } from './vehicle-coordinates';
import { VehicleRemainingFuel } from './vehicle-remaining-fuel';
import { VehicleTank } from './vehicle-tank';


@Injectable({ providedIn: 'root' })
export class VehiclesModelsFactory {

  public createVehicle(json: VehicleJson) {
    return new Vehicle(
      json.id,
      json.name,
      json.garage_number,
      json.has_platform,
      json.has_bottom_refuelling,
      json.has_dispensing_gun,
      new VehicleTank(json.tank_volume),
      json.has_anti_icing_fluid_fuel_tank
        ? new VehicleTank(json.anti_icing_fluid_tank_volume, json.anti_icing_fluid_fuel_tank_name)
        : null,
    );
  }

  public createDefaultVehicleRemainingFuel() {
    return new VehicleRemainingFuel(0);
  }

  public createVehicleRemainingFuel(json: RemainingFuelJson) {
    return new VehicleRemainingFuel(
      json.current_fuel_volume,
      json.current_fuel_mass,
      json.vehicle_name || null,
    );
  }

  public createVehicleCoordinates(json: VehicleCoordinatesJson): VehicleCoordinates {
    return new VehicleCoordinates(
      json.id,
      json.last_message_time,
      json.parking_status,
      json.connection_status,
      json.speed,
      json.latitude,
      json.longitude,
      json.target_platform_id || null,
    );
  }

}
