import { Routes } from "@angular/router";
import { KeycloakGuard } from "@shared/services/keycloak.guard";
import { AvailableAirportsGuard } from "@shared/services/system/available-airports.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "flights",
    pathMatch: "full",
  },
  {
    path: "orders",
    loadChildren: () =>
            import("./applications/applications.module").then(m => m.ApplicationsModule),
    data: { roles: ["Merchant", "Dispatcher", "Admin"], layout: "main" },
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "flights-old",
    loadChildren: () => import("./flights-old/flights-old.module").then(m => m.FlightsOldModule),
    data: { roles: ["Merchant", "Dispatcher", "Admin"], layout: "main" },
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "flights",
    loadChildren: () => import("./flights/route.flights.routing.module").then(m => m.RouteFlightsRoutingModule),
    data: { roles: ["Merchant", "Dispatcher", "Admin"], layout: "main" },
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "airport-map",
    loadChildren: () =>
      import("./airport-map/route.airport-map.routing.module").then(m => m.RouteAirportMapRoutingModule),
    data: {roles: ["Merchant", "Dispatcher", "Admin"], layout: "main"},
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "tasks",
    loadChildren: () => import("./tasks/tasks.module").then(m => m.TasksModule),
    data: { roles: ["Merchant", "Dispatcher", "Admin"], layout: "main" },
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "gantt",
    loadChildren: () =>
      import("./gantt/route.gantt.routing.module").then(m => m.RouteGanttRoutingModule),
    data: {roles: ["Merchant", "Dispatcher", "Admin"], layout: "main"},
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "display",
    loadChildren: () => import("./drivers-display/drivers-display.module").then(m => m.DriversDisplayModule),
    data: { roles: ["Merchant", "Dispatcher", "Admin"], layout: "display" },
    canActivate: [KeycloakGuard, AvailableAirportsGuard],
  },
  {
    path: "access-denied",
    loadChildren: () => import("./access-denied/access-denied.module").then(m => m.AccessDeniedModule),
  },
  {
    path: "**",
    loadChildren: () => import("./not-found/not-found.module").then(m => m.NotFoundModule),
    canActivate: [KeycloakGuard],
  },
];
